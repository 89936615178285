import React from 'react';
import { ModelDefinition, ModelDefinitionSection, ModelPropertyInfo, ModelPropertyType } from '../../redux/model/types';
import { DataGrid, GridActionsCellItem, GridColDef, GridEventListener, GridRenderCellParams, GridRenderEditCellParams, GridRowId, GridRowModel, GridRowModes, GridRowModesModel, GridRowParams, GridRowSelectionModel, MuiEvent } from '@mui/x-data-grid';
import { defaultModelDefinition, updateModelDefinition } from '../../redux/model/modelSlice';
import { useAppDispatch } from '../../redux/hooks';
import SnackBarOperations from '../../components/SnackBar/SnackBarOperations';
import yup from '../../validation/yup';
import { CheckBox, CheckBoxOutlineBlank, Save, Cancel, Edit, Delete } from '@mui/icons-material';
import { Autocomplete, TextField } from '@mui/material';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';

interface Props {
    projectModelDefinitions: ModelDefinition[],
    editModelDefinition: ModelDefinition,
    currentModelDefinition: ModelDefinition,
    selectedIds: GridRowSelectionModel,
    setSelectedIds: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>,
}

export default function ModelPropertyDataGrid(props: Props) {
    const { editModelDefinition, projectModelDefinitions, currentModelDefinition, selectedIds, setSelectedIds } = props;

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const dispatch = useAppDispatch();

    const handleEditClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
    }, []);

    const handleSaveClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
    }, []);

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        const changedModelDefinition = { ...editModelDefinition, properties: [...editModelDefinition.properties.filter(x => x.id !== id)] }
        dispatch(updateModelDefinition(changedModelDefinition));
    }, [dispatch, editModelDefinition]);

    const handleCancelClick = React.useCallback((id: GridRowId) => () => {
        setRowModesModel({
            ...rowModesModel,
            [id]: { mode: GridRowModes.View, ignoreModifications: true },
        });
    }, [setRowModesModel, rowModesModel]);
    
    const getPropertyValidationSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Property tag name is required').unique('Property tag name must be unique', (val: string) => {
            return !currentModelDefinition.properties.filter(x => x.id !== id).map(x => x.name).includes(val);
        }),
        order: yup
            .number()
            .required('Order is required')
            .test('uniqueOrder', 'Order must be unique', function (value) {
              if (value == null) return false;
              const otherOrders = currentModelDefinition.properties
                .filter((section) => section.id !== id && section.order != null)
                .map((section) => section.order);
              return !otherOrders.includes(value);
            }),
    }), [currentModelDefinition])

    const processRowUpdate = React.useCallback(async (newRow: GridRowModel, oldRow: GridRowModel) => {
        const updatedRow = { ...newRow, isNew: false };

        try {
            await getPropertyValidationSchema(newRow.id).validate(updatedRow);
            const propertyInfo = newRow as ModelPropertyInfo;
            const changedModel: ModelDefinition = { ...currentModelDefinition, properties: [...currentModelDefinition.properties.filter(x => x.id !== propertyInfo.id), propertyInfo] }
            await dispatch(updateModelDefinition(changedModel));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                err.errors.forEach((strErr) => SnackBarOperations.error(strErr));
                throw err;
            }
        }
        return updatedRow;
    }, [dispatch, getPropertyValidationSchema]);
    
    const handleRowEditStart = React.useCallback((
        params: GridRowParams,
        event: MuiEvent<React.SyntheticEvent>,
    ) => {
        event.defaultMuiPrevented = true;
    }, []);

    const handleRowEditStop: GridEventListener<'rowEditStop'> = React.useCallback((params, event) => {
        event.defaultMuiPrevented = true;
    }, []);

    const sectionOptions = editModelDefinition.modelDefinitionSections.map(x => { 
        return { code: x.id, name: x.name } 
    });

    const columns: GridColDef[] = [
        {
            field: 'id',
            headerName: 'id',
            hideable: false
        },
        {
            field: 'order',
            headerName: '',
            width: 100,
            type: 'number',
            editable: true
        },
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
            editable: true
        },
        {
            field: 'type',
            headerName: 'Type',
            width: 100,
            type: 'singleSelect',
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: [
                { code: ModelPropertyType.string, name: 'string' },
                { code: ModelPropertyType.number, name: 'number' },
                { code: ModelPropertyType.boolean, name: 'boolean' },
                { code: ModelPropertyType.model, name: 'model' },
                { code: ModelPropertyType.stringArray, name: 'List of texts' },
                { code: ModelPropertyType.numberArray, name: 'List of numbers' },
                { code: ModelPropertyType.modelArray, name: 'List of models' },
            ],
            editable: true
        },
        {
            field: 'span',
            headerName: 'Span',
            width: 100,
            type: 'singleSelect',
            getOptionValue: (value: any) => value.code,
            getOptionLabel: (value: any) => value.name,
            valueOptions: [
                { code: 2, name: 2 },
                { code: 3, name: 3 },
                { code: 4, name: 4 },
                { code: 6, name: 6 },
                { code: 8, name: 8 },
                { code: 10, name: 10 },
                { code: 12, name: 12 },
            ],
            editable: true
        },
        {
            field: 'modelDefinitionSectionId',
            headerName: 'Section',
            minWidth: 100,
            flex: 1,
            type: 'singleSelect',
            valueGetter: (params) => params?.value ?? '',
            getOptionValue: (value: any) => value?.code || '',
            getOptionLabel: (value: any) => value?.name || 'none',
            valueOptions: [
                { code: '', name: 'none' },
                ...sectionOptions
            ],
            editable: true
        },
        {
            field: 'propertyModelDefinitionId',
            headerName: 'Definition',
            flex: 0.5,
            editable: true,
            renderCell: (params: GridRenderCellParams<ModelPropertyInfo, number>) => {
                if (params.row.type !== ModelPropertyType.model && params.row.type !== ModelPropertyType.modelArray)
                    return <></>
                const definition = projectModelDefinitions.find(x => x.id === params.value);
                return definition?.name || 'Select';
            },
            renderEditCell: (params: GridRenderEditCellParams) => {
                if (params.row.type !== ModelPropertyType.model && params.row.type !== ModelPropertyType.modelArray)
                    return <></>

                const selectedDefinition = projectModelDefinitions.find((option) => option.id === params.value) || null;

                return (
                    <Autocomplete
                        value={selectedDefinition}
                        options={projectModelDefinitions}
                        getOptionLabel={(option) => option.name}
                        onChange={(event, newValue) => {
                            params.api.setEditCellValue({ id: params.id, field: 'propertyModelDefinitionId', value: newValue?.id });
                        }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                        fullWidth
                    />
                );
            },
        },
        {
            field: "isDisplayed",
            headerName: "Displayed",
            minWidth: 100,
            type: 'boolean',
            editable: true,
            renderCell(params) {
                return params.value ? <CheckBox /> : <CheckBoxOutlineBlank />;
            },
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

                if (isInEditMode) {
                    return [
                        <GridActionsCellItem
                            key="save"
                            icon={<Save />}
                            label="Save"
                            onClick={handleSaveClick(id)}
                        />,
                        <GridActionsCellItem
                            key="cancel"
                            icon={<Cancel />}
                            label="Cancel"
                            className="textPrimary"
                            onClick={handleCancelClick(id)}
                            color="inherit"
                        />,
                    ];
                }

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={handleEditClick(id)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    return (

        <DataGrid
            sx={{ flex: 1 }}
            rows={currentModelDefinition.properties}
            columns={columns}
            pageSizeOptions={[10]}
            checkboxSelection
            onRowSelectionModelChange={(newSM) => {
                setSelectedIds(newSM);
            }}
            editMode='row'
            rowModesModel={rowModesModel}
            onRowModesModelChange={setRowModesModel}
            onRowEditStart={handleRowEditStart}
            onRowEditStop={handleRowEditStop}
            processRowUpdate={processRowUpdate}
            rowSelectionModel={selectedIds}
            disableRowSelectionOnClick
            initialState={{
                sorting: {
                    sortModel: [{ field: 'id', sort: 'asc' }], // Sort by ID
                },
                columns: {
                    columnVisibilityModel: {
                        id: false, // Hide the ID column
                    },
                },
            }}
        />
    )
}