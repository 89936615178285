import * as React from "react";
import { Outlet, Route, Routes } from "react-router";
import AppBar from "./AppBar";
import Login from "./auth/Login";
import Project from "./project/Project";
import User from "./users/User";
import Users from "./users/Users";
import GlobalTemplates from "./template/GlobalTemplates";
import ProjectTemplates from "./template/ProjectTemplates";
import Media from "./media/Media";
import Dashboard from "./dashboard/Dashboard";
import ProjectSettings from "./project/ProjectSettings";
import DataModelForm from "./project/ProjectSettingsTab/DataModelForm";
import DataSetForm from "./project/ProjectSettingsTab/DataSetForm";
import GlobalAnimations from "./animation/GlobalAnimations";
import BuilderBar from "./BuilderBar";
import AnimationBar from "./AnimationBar";
import ProjectAnimations from "./animation/ProjectAnimations";
import ContextOutlet from "../context/ContextOutlet";
import RequestForm from "./project/ProjectSettingsTab/RequestForm";
import DataEntryForm from "./project/ProjectSettingsTab/DataEntryForm";
import Deployment from "./deployment/Deployment";
import ProjectContextProvider from "./project/context/ProjectContext";
import GlobalActionSequences from "./actions/GlobalActionSequences";
import GlobalSequenceBuilder from "./actions/SequenceBuilder/GlobalSequenceBuilder";
import ActionDebugContextOutlet from "../context/ActionDebugContextOutlet";
import MailTemplateForm from "./project/ProjectSettingsTab/MailTemplateForm";
import ProjectModelDefinitions from "./model/ProjectModelDefinitions";
import ProjectModelDefinition from "./model/ProjectModelDefinition";
import ProjectModels from "./model/ProjectModels";
import ProjectModel from "./model/ProjectModel";

const App = () => {
  return (
    <Routes>
      <Route element={<AppBar />}>
        <Route path="/" element={<Dashboard />} />
        <Route path="/project-detail">
          <Route
            path=":projectId"
            element={
              <ProjectContextProvider>
                <Outlet />
              </ProjectContextProvider>
            }
          >
            <Route index element={<Project />} />
            <Route path="templates" element={<ProjectTemplates />} />
            <Route path="animations" element={<ProjectAnimations />} />
            <Route path="media" element={<Media />} />
            <Route path="definitions" element={<ProjectModelDefinitions />} />
            <Route path="model" >
                <Route path=":modelId" element={<ProjectModel />} />
            </Route>
            <Route path="models" >
                <Route path=":definitionId" element={<ProjectModels />} />
            </Route>
            <Route path="definition">
                <Route path=":definitionId" element={<ProjectModelDefinition />} />
            </Route>
            <Route path="settings">
              <Route path=":tabId" element={<ProjectSettings />} />
              <Route path="model">
                <Route path=":modelId" element={<DataModelForm />} />
              </Route>
              <Route path="dataset">
                <Route path=":dataSetId" element={<DataSetForm />} />
              </Route>
              <Route path="entry">
                <Route path=":entryId" element={<DataEntryForm />} />
              </Route>
              <Route path="request">
                <Route path=":requestId" element={<RequestForm />} />
              </Route>
              <Route path="mailtemplate">
                <Route path=":templateId" element={<MailTemplateForm />} />
              </Route>
            </Route>
          </Route>
        </Route>
        <Route path="/users">
          <Route index element={<Users />} />
          <Route path=":userId" element={<User />} />
        </Route>
        <Route path="/global-templates" element={<GlobalTemplates />} />
        <Route path="/project-templates" element={<ProjectTemplates />} />
        <Route path="/global-animations" element={<GlobalAnimations />} />
        <Route
          path="/global-actionsequences"
          element={<GlobalActionSequences />}
        />
        <Route path="/media" element={<Media />} />
      </Route>
      <Route path="/auth" element={<Login />} />
      <Route path="/sequencebuilder" element={<ActionDebugContextOutlet />}>
        <Route path=":sequenceId" element={<GlobalSequenceBuilder />} />
      </Route>
      <Route path="/builder" element={<ContextOutlet />}>
        <Route path=":projectId">
          <Route path=":ref">
            <Route path=":refId" element={<BuilderBar />} />
          </Route>
        </Route>
      </Route>
      <Route path="/animator">
        <Route path=":projectId">
          <Route path=":animationId" element={<AnimationBar />} />
        </Route>
      </Route>
      <Route path="/deployment">
        <Route path=":projectId" element={<Deployment />} />
      </Route>
    </Routes>
  );
};

export default App;
