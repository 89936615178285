import React from 'react';
import useRouteParam from '../../hooks/useRouteParam';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { selectModelByDefinitionId as selectModelsByDefinitionId, selectModels, selectModelDefinitionById, createModel, defaultModel, deleteModel, getModelDefinitions, getModels } from '../../redux/model/modelSlice';
import { Add, DeleteOutlined, LanguageOutlined } from '@mui/icons-material';
import TitleBox from '../widgets/TitleBox';
import { Button, IconButton, Paper, useTheme } from '@mui/material';
import ModelDataGrid from './ModelDataGrid';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';
import { GridRowSelectionModel } from '@mui/x-data-grid';

export default function ProjectModels() {

    const theme = useTheme()
    const dispatch = useAppDispatch();

    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    
    const projectId = useRouteParam('projectId');
    const definitionId = useRouteParam('definitionId');
    const allModelsOfDefinition = useAppSelector(selectModelsByDefinitionId(definitionId));
    const definition = useAppSelector(selectModelDefinitionById(definitionId));

    React.useEffect(() => {
        dispatch(getModelDefinitions(projectId));
        dispatch(getModels(projectId));
    }, [])

    const handleDataEntryAdd = React.useCallback(async () => {
        dispatch(createModel({...defaultModel, modelDefinitionId: definition.id, projectId}))
    }, [dispatch, definition, projectId]);

    const handleSelectedModelsDelete = React.useCallback(() => {
        selectedIds.forEach(id => dispatch(deleteModel(Number(id))));
    }, [selectedIds, dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit model definition'
                subTitle='Lorem ipsum'>
                    <Button
                    variant="contained"
                    sx={{ marginRight: theme.spacing(1) }}
                    onClick={handleDataEntryAdd}
                    endIcon={<Add/>}
                >
                    ADD
                </Button>
                <DeleteConfirmationDialog onDeleteConfirm={handleSelectedModelsDelete}>
                    <IconButton
                        component="label"
                        disabled={selectedIds.length < 1}
                    >
                        <DeleteOutlined />
                    </IconButton>
                </DeleteConfirmationDialog>
            </TitleBox>
            <Paper sx={{ width: "100%", height: "700px", padding: theme.spacing(2) }}>
                <ModelDataGrid models={allModelsOfDefinition} definition={definition} selectedIds={selectedIds} setSelectedIds={setSelectedIds}/>
            </Paper>
        </>
    );
}