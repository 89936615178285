import { LanguageOutlined, CheckBoxOutlineBlank, CheckBox } from "@mui/icons-material";
import { Checkbox, FormControlLabel, Grid, Paper, Stack, TextField, useTheme } from "@mui/material";
import React from "react";
import useRouteParam from "../../hooks/useRouteParam";
import { useAppSelector, useAppDispatch, BackendError } from "../../redux/hooks";
import yup from "../../validation/yup";
import TitleBox from "../widgets/TitleBox";
import { defaultModel, getModelDefinitions, getModels, selectModelById, selectModelDefinitionById, updateModel } from "../../redux/model/modelSlice";
import { Model, ModelDefinitionSection, ModelProperty, ModelPropertyType } from "../../redux/model/types";
import ModelSelectionInput from "./ModelSelectionInput";
import ModelsSelectionList from "./ModelsSelectionList";
import { debounce } from "lodash";

export default function ProjectModel() {

    const modelId = useRouteParam('modelId');
    const projectId = useRouteParam('projectId');
    const theme = useTheme();
    const [editModel, setEditModel] = React.useState(defaultModel);
    const model = useAppSelector(selectModelById(modelId));
    const definition = useAppSelector(selectModelDefinitionById(model?.modelDefinitionId ?? 0));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (model)
            setEditModel({ ...model });
    }, [model])

    React.useEffect(() => {
        dispatch(getModelDefinitions(projectId));
        dispatch(getModels(projectId));
    }, [])

    const handleModelSaveAsync = React.useCallback(async (changedModel: Model) => {
        await dispatch(updateModel(changedModel));
    }, [dispatch]);

    const handleEntryDataChange = React.useCallback((id: number, value: any) => {
        let changedModel = {
            ...editModel,
            properties: editModel.properties.map(prop =>
                prop.id === id ? { ...prop } : prop
            )
        };
        let changedPropIndex = changedModel.properties.findIndex(x => x.id === id);

        if (changedPropIndex < 0)
            return;

        if (changedModel.properties[changedPropIndex].type == ModelPropertyType.number) {
            const validPattern = /^-?(?:\d+\.)?\d*$|^-?\.\d+$/;
            if (value !== "" && !validPattern.test(value)) return;
        }

        changedModel.properties[changedPropIndex].referencedValue = value;

        if ([ModelPropertyType.model, ModelPropertyType.modelArray].some(
            (type) => type === changedModel.properties[changedPropIndex].type
        )) {
            handleModelSaveAsync(changedModel);
        } else {
            setEditModel(changedModel);
            debouncedChangeHandler(changedModel);
        }

    }, [setEditModel, editModel, handleModelSaveAsync]);


    const debouncedChangeHandler = React.useCallback(
        debounce(handleModelSaveAsync, 500),
        [handleModelSaveAsync],
    );

    function getPropertyInput(prop: ModelProperty) {
        switch (prop.type) {
            case ModelPropertyType.boolean:
                return (
                    <FormControlLabel
                        value="end"
                        control={<Checkbox
                            icon={<CheckBoxOutlineBlank fontSize="small" />}
                            checkedIcon={<CheckBox fontSize="small" />}
                            color="primary"
                            checked={prop.referencedValue}
                            onChange={(e, checked) => handleEntryDataChange(prop.id, checked)} />}
                        label={prop.name}
                        key={prop.id}
                        labelPlacement="end" />
                );
            case ModelPropertyType.number:
                return (
                    <TextField
                        variant="filled"
                        id={`${prop.name}-${modelId}`}
                        label={prop.name}
                        key={prop.id}
                        fullWidth
                        value={prop.referencedValue}
                        onChange={(e) => handleEntryDataChange(prop.id, e.target.value)} />
                );
            case ModelPropertyType.model:
                return (
                    <ModelSelectionInput modelProperty={prop} handleEntryDataChange={handleEntryDataChange} key={prop.id} />
                );
            case ModelPropertyType.modelArray:
                return (
                    <ModelsSelectionList modelProperty={prop} handleEntryDataChange={handleEntryDataChange} key={prop.id} />
                );
            default:
                return (
                    <TextField
                        id={`${prop.name}-${modelId}`}
                        label={prop.name}
                        key={prop.id}
                        variant="filled"
                        value={prop.referencedValue}
                        fullWidth
                        onChange={(e) => handleEntryDataChange(prop.id, e.target.value)} />
                );
        }
    }

    function RenderProperties(sectionTitle: string, span: number, sectionId: number, properties: ModelProperty[]): JSX.Element {
        return <Grid item xs={span} key={sectionId}>
            <Paper sx={{ padding: theme.spacing(2) }}>
                <TitleBox

                    mainTitle={sectionTitle}
                    maintitleVariant="h5" />
                <Grid
                    container
                    spacing={theme.spacing(2)}
                >
                    {[...properties]
                        .sort((a, b) => a.order - b.order)
                        .map((prop) => {
                            return (
                                <Grid item xs={prop.span} key={prop.id}>
                                    {getPropertyInput(prop)}
                                </Grid>
                            );
                        })}
                </Grid>
            </Paper>
        </Grid>;
    }

    function RenderDefaultProperties(): JSX.Element {
        const properties = editModel.properties.filter(x => !x.sectionId);

        if (properties.length)
            return RenderProperties('', 12, 0, properties);
        else
            return <></>
    }

    const sectionProperties = [...definition.modelDefinitionSections]
        .sort((a, b) => a.order - b.order)
        .map((section) => {
            const properties = editModel.properties.filter(x => section.id == x.sectionId);
            return RenderProperties(section.name, section.span, section.id, properties);
        });

    const defaultProperties = RenderDefaultProperties();

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit entry'
                subTitle='Lorem ipsum'>
            </TitleBox>
            <Grid
                container
                spacing={theme.spacing(2)}
            >
                {defaultProperties}
                {sectionProperties}
            </Grid>
        </>
    );
}