import React, { ChangeEvent } from 'react'
import { Model, ModelProperty, ModelPropertyType, ModelReference } from '../../redux/model/types'
import { Edit, Delete, LinkOff, Add } from '@mui/icons-material';
import { GridRowId, GridColDef, GridActionsCellItem, DataGrid, GridRowSelectionModel } from '@mui/x-data-grid';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';
import { useAppSelector } from '../../redux/hooks';
import { selectModelByDefinitionId, selectModelDefinitionById } from '../../redux/model/modelSlice';
import { useNavigate } from 'react-router';
import useRouteParam from '../../hooks/useRouteParam';
import TitleBox from '../widgets/TitleBox';
import { Box, Button, useTheme } from '@mui/material';
import ModelSelectionDialog from './ModelSelectionDialog';
import ModelSelectionDataGrid from './ModelSelectionDataGrid';
import { xorBy } from 'lodash';

interface Props {
    modelProperty: ModelProperty,
    handleEntryDataChange: (id: number, value: any) => void,
}

export default function ModelsSelectionList(props: Props) {
    const { modelProperty, handleEntryDataChange } = props;

    const [open, setOpen] = React.useState(false);
    const [searchQuery, setSearchQuery] = React.useState("");
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const projectId = useRouteParam('projectId');
    const navigate = useNavigate();
    const theme = useTheme();

    const definition = useAppSelector(selectModelDefinitionById(modelProperty.modelDefinitionId));
    const models = useAppSelector(selectModelByDefinitionId(definition.id));

    const filteredModels = modelProperty.referencedValue !== null
        ? models.filter(x => !(modelProperty.referencedValue as Model[]).some(y => y.id === x.id))
        : models;

    const entryGridSource = (modelProperty.referencedValue as Model[])?.map((model) => {
        if (model?.properties) 
            return {
                id: model.id, ...model?.properties
                    ?.reduce((trans: any, prop: ModelProperty) => {
                        trans[prop.name] = prop.referencedValue;
                        return trans;
                    }, {})
            };
        else 
            return [];
    }) ?? [];

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        const data: ModelReference[] = (modelProperty.referencedValue as Model[])
            .filter(x => x.id !== id)
            .map(x => { return { modelId: Number(x.id) } });
        handleEntryDataChange(modelProperty.id, data);
    }, [modelProperty]);

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSearchChange = (event: ChangeEvent<HTMLInputElement>) => {
        setSearchQuery(event.target.value);
    };

    const handleSubmit = () => {
        if (selectedIds.length) {
            const references: ModelReference[] = (modelProperty.referencedValue as Model[]).map(x => { return { modelId: x.id} })
            const data: ModelReference[] = [...references, ...selectedIds.map(x => { return { modelId: Number(x) } })];
            handleEntryDataChange(modelProperty.id, data);
        }

        setOpen(false);
    };

    const columns: GridColDef[] = [
        ...(definition.properties
            .filter(x => x.isDisplayed)
            .map((p): GridColDef => {
                return {
                    field: p.name,
                    headerName: p.name,
                    minWidth: 50,
                    flex: p.type === ModelPropertyType.boolean ? 0 : 1,
                    editable: true,
                }
            })),
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => navigate(`/project-detail/${projectId}/model/${id}`)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<LinkOff />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];


    return (
        <>
            <TitleBox
                mainTitle={definition.name}
                maintitleVariant='h5'>
                <Button
                    variant="contained"
                    onClick={handleOpen}
                    endIcon={<Add />}
                >
                    ADD
                </Button>
            </TitleBox>
            <Box sx={{ paddingTop: theme.spacing(1) }}>
                <DataGrid
                    sx={{ minHeight: '500px' }}
                    rows={entryGridSource}
                    columns={columns}
                    pageSizeOptions={[10]}
                    disableRowSelectionOnClick
                />
            </Box>
            <ModelSelectionDialog
                open={open}
                definition={definition}
                searchQuery={searchQuery}
                handleClose={handleClose}
                handleSearchChange={handleSearchChange}
                handleSubmit={handleSubmit}>
                <ModelSelectionDataGrid
                    definition={definition}
                    selectedIds={selectedIds}
                    setSelectedIds={setSelectedIds}
                    models={filteredModels} />
            </ModelSelectionDialog>
        </>
    )
}