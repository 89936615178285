import { Dialog, DialogTitle, TextField, DialogContent, DialogActions, Button } from '@mui/material';
import React, { ChangeEvent, Children, ReactNode } from 'react';
import TitleBox from '../widgets/TitleBox';
import ModelSelectionDataGrid from './ModelSelectionDataGrid';
import { ModelDefinition } from '../../redux/model/types';

interface Props {
    open: boolean,
    searchQuery: string
    handleClose: () => void,
    handleSubmit: () => void,
    handleSearchChange: (event: ChangeEvent<HTMLInputElement>) => void,
    definition: ModelDefinition,
    children: ReactNode
}

export default function ModelSelectionDialog(props: Props) {
    const { open, handleClose, handleSubmit, handleSearchChange, searchQuery, definition, children } = props

    return (
        <Dialog open={open} onClose={handleClose} maxWidth='md' fullWidth>
            <DialogTitle>
                <TitleBox mainTitle={definition.name}>
                    <TextField
                        autoFocus
                        margin="dense"
                        label="Search"
                        type="text"
                        fullWidth
                        value={searchQuery}
                        onChange={handleSearchChange}
                    />
                </TitleBox>
            </DialogTitle>
            <DialogContent sx={{ height: '500px' }}>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} color="primary">
                    Cancel
                </Button>
                <Button onClick={handleSubmit} color="primary">
                    Submit
                </Button>
            </DialogActions>
        </Dialog>
    )
}