import { Edit, Delete } from "@mui/icons-material";
import { GridRowSelectionModel, GridRowModesModel, GridRowId, GridRowModes, GridColDef, GridActionsCellItem, GridRowParams, MuiEvent, GridEventListener, GridRowModel, DataGrid } from "@mui/x-data-grid";
import React from "react";
import useRouteParam from "../../hooks/useRouteParam";
import { useAppDispatch } from "../../redux/hooks";
import DeleteConfirmationDialog from "../utils/DeleteConfirmationDialog";
import { Model, ModelDefinition, ModelProperty, ModelPropertyType } from "../../redux/model/types";
import { deleteModel } from "../../redux/model/modelSlice";
import { useNavigate } from "react-router";

interface Props {
    models: Model[],
    definition: ModelDefinition,
    selectedIds: GridRowSelectionModel,
    setSelectedIds: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>,
    multiSelect?: boolean
}

export default function ModelSelectionDataGrid(props: Props) {
    const { models, definition, selectedIds, setSelectedIds, multiSelect = false } = props;

    const entryGridSource = models?.map((model) => {
        return {
            id: model.id, ...model?.properties
                .reduce((trans: any, prop: ModelProperty) => {
                    trans[prop.name] = prop.referencedValue;
                    return trans;
                }, {})
        };
    }) ?? [];

    const columns: GridColDef[] = [
        ...(definition.properties
            .filter(x => x.isDisplayed)   
            .map((p): GridColDef => {
            return {
                field: p.name,
                headerName: p.name,
                minWidth: 50,
                flex: p.type === ModelPropertyType.boolean ? 0 : 1,
                editable: true,
            }
        }))
    ];

    return (
        <DataGrid
            rows={entryGridSource}
            columns={columns}
            pageSizeOptions={[10]}
            onRowSelectionModelChange={(newSM) => {
                setSelectedIds(newSM);
            }}
            rowSelectionModel={selectedIds}            
            isCellEditable={() => false}
            checkboxSelection={multiSelect}
        />
    );
}
