import {
  combineReducers,
  configureStore,
  PreloadedState,
} from "@reduxjs/toolkit";
import animationReducer from "./animation/animationSlice";
import authReducer from "./auth/authSlice";
import blockReducer from "./blocks/blockSlice";
import dyndataReducer from "./dyndata/dyndataSlice";
import languageReducer from "./language/languageSlice";
import mediaReducer from "./media/mediaSlice";
import templateReducer from "./template/templateSlice";
import pageReducer from "./page/pageSlice";
import projectReducer from "./project/projectSlice";
import usersReducer from "./users/usersSlice";
import mailReducer from "./mail/mailSlice";
import requestReducer from "./requests/requestSlice";
import fontReducer from "./fonts/fontSlice";
import sequenceReducer from "./actionsequence/sequenceSlice";
import modelsReducer from "./model/modelSlice";

const rootReducer = combineReducers({
  animations: animationReducer,
  auth: authReducer,
  blocks: blockReducer,
  language: languageReducer,
  dyndata: dyndataReducer,
  media: mediaReducer,
  templates: templateReducer,
  pages: pageReducer,
  projects: projectReducer,
  users: usersReducer,
  mail: mailReducer,
  requests: requestReducer,
  fonts: fontReducer,
  sequences: sequenceReducer,
  models: modelsReducer,
});

export const setupStore = (preloadedState?: PreloadedState<RootState>) => {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
  });
};

export const store = setupStore();

export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
