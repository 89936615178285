import { Edit, Delete, Mode } from "@mui/icons-material";
import { GridRowSelectionModel, GridRowModesModel, GridRowId, GridRowModes, GridColDef, GridActionsCellItem, GridRowParams, MuiEvent, GridEventListener, GridRowModel, DataGrid } from "@mui/x-data-grid";
import React from "react";
import useRouteParam from "../../hooks/useRouteParam";
import { useAppDispatch } from "../../redux/hooks";
import DeleteConfirmationDialog from "../utils/DeleteConfirmationDialog";
import { Model, ModelDefinition, ModelProperty, ModelPropertyType } from "../../redux/model/types";
import { deleteModel } from "../../redux/model/modelSlice";
import { useNavigate } from "react-router";
import { getModelDisplayName, getModelsDisplayName } from "../../Utility";

interface Props {
    models: Model[],
    definition: ModelDefinition,
    selectedIds: GridRowSelectionModel,
    setSelectedIds: React.Dispatch<React.SetStateAction<GridRowSelectionModel>>,
}

export default function ModelDataGrid(props: Props) {
    const { models, definition, selectedIds, setSelectedIds } = props;

    const projectId = useRouteParam('projectId');

    const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>({});

    const dispatch = useAppDispatch();
    const navigate = useNavigate();

    const entryGridSource = models?.map((model) => {
        return {
            id: model.id, ...model?.properties
                .reduce((trans: any, prop: ModelProperty) => {
                    trans[prop.name] = prop.referencedValue;
                    return trans;
                }, {})
        };
    }) ?? [];

    const handleDeleteClick = React.useCallback((id: GridRowId) => () => {
        dispatch(deleteModel(Number(id)));
    }, [definition, dispatch]);

    const columns: GridColDef[] = [
        ...(definition.properties
            .filter(x => x.isDisplayed)
            .map((p): GridColDef => {
                return {
                    field: p.name,
                    headerName: p.name,
                    minWidth: 50,
                    flex: p.type === ModelPropertyType.boolean ? 0 : 1,
                    editable: true,
                    renderCell: (params) => {
                        if (params.value === null)
                            return "";

                        if (p.type === ModelPropertyType.model) {
                            return getModelDisplayName(params.value as Model)
                        } else if (p.type === ModelPropertyType.modelArray) {
                            const models = params.value as Model[];
                            return getModelsDisplayName(models);
                        }

                        return params.value;
                    }
                }
            })),
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {

                return [
                    <GridActionsCellItem
                        key="edit"
                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => navigate(`/project-detail/${projectId}/model/${id}`)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={handleDeleteClick(id)}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    return (
        <DataGrid
            rows={entryGridSource}
            columns={columns}
            pageSizeOptions={[10]}
            checkboxSelection
            onRowSelectionModelChange={(newSM) => {
                setSelectedIds(newSM);
            }}
            rowSelectionModel={selectedIds}
            disableRowSelectionOnClick
            rowModesModel={rowModesModel}
            onRowModesModelChange={setRowModesModel}
        />
    );
}
