import { Button, Grid, IconButton, Paper, Stack, TextField, useTheme, Box, Tooltip, debounce } from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import BackupOutlinedIcon from '@mui/icons-material/BackupOutlined';
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined';
import InsertChartOutlinedIcon from '@mui/icons-material/InsertChartOutlined';
import AddIcon from '@mui/icons-material/Add';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import SegmentIcon from '@mui/icons-material/Segment';
import { useAppDispatch, useAppSelector } from '../../redux/hooks';
import { defaultProjectData, deleteProject, selectProjectById, updateProject } from '../../redux/project/projectSlice';
import { ProjectData } from '../../redux/project/types';
import { useNavigate } from 'react-router';
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from '@mui/x-data-grid';
import TitleBox from '../widgets/TitleBox';
import CardWidget from '../widgets/CardWidget';
import { selectProjectPermissions } from '../../redux/auth/authSlice';
import { PROJECT_DELETE_ROLES, PROJECT_EDIT_ROLES } from '../../redux/auth/types';
import ConfirmationPopover from '../utils/ConfirmationPopover';
import { createPage, defaultPageData, deletePage, getPages, selectPages } from '../../redux/page/pageSlice';
import { selectProjectTemplates } from '../../redux/template/templateSlice';
import useRouteParam from '../../hooks/useRouteParam';
import { getFiles, selectMedia } from '../../redux/media/mediaSlice';
import { ReferenceType } from '../../redux/blocks/types';
import { selectProjectAnimations } from '../../redux/animation/animationSlice';
import MorePopover from '../utils/MorePopOver';
import yup from '../../validation/yup';
import SnackBarOperations from '../../components/SnackBar/SnackBarOperations';
import { BackendError } from '../../redux/hooks';
import Deployments from './Deployments';
import { Edit, Delete } from '@mui/icons-material';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';
import { getModels, selectModelDefinitions, selectModels } from '../../redux/model/modelSlice';

export default function Project() {
    const theme = useTheme();
    const projectId = useRouteParam('projectId');

    const [projectDetails, setProjectDetails] = useState<ProjectData>({ ...defaultProjectData, projectRoles: [] });

    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);

    const projectRoles = useAppSelector(selectProjectPermissions(projectId));

    const canEdit = useMemo(() => projectRoles.some(x => PROJECT_EDIT_ROLES.includes(x)), [projectRoles])
    const canDelete = useMemo(() => projectRoles.some(x => PROJECT_DELETE_ROLES.includes(x)), [projectRoles]);

    const selectedProject = useAppSelector(selectProjectById(projectId));
    const templates = useAppSelector(selectProjectTemplates(projectId));
    const animations = useAppSelector(selectProjectAnimations);
    const projectPages = useAppSelector(selectPages);
    const mediaFiles = useAppSelector(selectMedia);
    const modelDefinitions = useAppSelector(selectModelDefinitions);
    const navigate = useNavigate();
    const dispatch = useAppDispatch();

    const projectBaseValidationSchema = React.useMemo(() => yup.object().shape({
        name: yup.string().required('Non-empty project name is required.'),
    }), []);

    useEffect(() => {
        if (selectedProject)
            setProjectDetails(selectedProject)

        dispatch(getPages(projectId));
        dispatch(getFiles(projectId));
        dispatch(getModels(projectId));
    }, [selectedProject]);
    
    const handleProjectDetailsSave = React.useCallback(async (project: ProjectData) => {
        try {
            await projectBaseValidationSchema.validate(project);
            await dispatch(updateProject({ id: project.id, update: { name: project.name } }));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            }
            if (err instanceof BackendError) {
                SnackBarOperations.error(`Error saving project details: ${err.message}`);
            }
        }
    }, [dispatch, projectBaseValidationSchema]);

    const debouncedChangeHandler = useCallback(
        debounce(handleProjectDetailsSave, 500),
        [handleProjectDetailsSave],
    );

    const handleProjectDetailsChange = React.useCallback((project: ProjectData) => {
        setProjectDetails(project);
        debouncedChangeHandler(project);
    }, [setProjectDetails]);

    const handleProjectDelete = React.useCallback(() => {
        dispatch(deleteProject(projectDetails.id))
            .then(() => {
                navigate("/");
            });
    }, [dispatch, projectDetails.id, navigate]);

    const handlePagesDelete = React.useCallback(() => {
        selectedIds.forEach(id => {
            dispatch(deletePage(Number(id)));
        });

    }, [dispatch, selectedIds]);

    const handlePageDelete = React.useCallback((id: number) => {
        dispatch(deletePage(id));
    }, [dispatch]);

    const handlePageCreate = React.useCallback(() => {
        dispatch(createPage({ ...defaultPageData, projectId: projectId }))
            .unwrap()
            .then(payload => {
                navigate(`/builder/${projectId}/${ReferenceType.Page}/${payload.id}`);
            });
    }, [dispatch, projectId]);

    const columns: GridColDef[] = [
        {
            field: 'name',
            headerName: 'Name',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'metaTitle',
            headerName: 'Meta title',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'metaDescription',
            headerName: 'Meta description',
            minWidth: 100,
            flex: 1,
        },
        {
            field: 'createdDate',
            headerName: 'Created on',
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                return (
                    <>
                        {new Date(params.value).toLocaleString()}
                    </>
                )
            },
        },
        {
            field: 'updatedDate',
            headerName: 'updated on',
            minWidth: 100,
            flex: 1,
            renderCell(params) {
                return (
                    <>
                        {new Date(params.value).toLocaleString()}
                    </>
                )
            },
        },
        {
            field: 'actions',
            type: 'actions',
            cellClassName: 'actions',
            headerName: 'Actions',
            getActions: ({ id, row }) => {
                return [
                    <GridActionsCellItem
                        key="edit"

                        icon={<Edit />}
                        label="Edit"
                        disabled={row.isDefault}
                        className="textPrimary"
                        onClick={() => navigate(`/builder/${projectId}/${ReferenceType.Page}/${id}`)}
                        color="inherit"
                    />,
                    <DeleteConfirmationDialog onDeleteConfirm={() => handlePageDelete(Number(id))}>
                        <GridActionsCellItem
                            key="delete"
                            icon={<Delete />}
                            label="Delete"
                            disabled={row.isDefault}
                            color="inherit"
                        />
                    </DeleteConfirmationDialog>,
                ];
            },
        }
    ];

    return (<>
        <TitleBox
            avatarIcon={<SegmentIcon />}
            mainTitle={projectDetails?.name ?? ''}
            subTitle='Project details and assets'
        >
            <Box sx={{
                display: 'inline-flex',
                '&>*': {
                    marginRight: theme.spacing(1)
                }
            }}>
            </Box>
            <Tooltip title="Deploy project">
                <span>
                    <IconButton color="primary" component="label" onClick={() => navigate(`/deployment/${projectId}`, { replace: true })}>
                        <BackupOutlinedIcon />
                    </IconButton>
                </span>
            </Tooltip>
            <Tooltip title="Delete project">
                <span>
                    <DeleteConfirmationDialog onDeleteConfirm={handleProjectDelete}>
                        <IconButton onClick={handleProjectDelete} disabled={!canDelete} component="label">
                            <DeleteOutlinedIcon />
                        </IconButton>
                    </DeleteConfirmationDialog>
                </span>
            </Tooltip>
            <Tooltip title="Project settings">
                <span>
                    <IconButton disabled={!canEdit} component="label" onClick={() => navigate(`/project-detail/${projectId}/settings/0`)}>
                        <SettingsOutlinedIcon />
                    </IconButton>
                </span>
            </Tooltip>
        </TitleBox>
        <Grid container spacing={3}>
            <Grid item xs={12}>
                <Paper>
                    <Stack direction="row" spacing={2} padding={2}>
                        <TextField
                            id="project-name"
                            label="Name"
                            variant="filled"
                            value={projectDetails.name}
                            onChange={(e) => handleProjectDetailsChange({ ...projectDetails, name: e.target.value })} />
                    </Stack>
                </Paper>
            </Grid>
            <Grid item xs={8}>
                <Paper sx={{ width: '100%', padding: theme.spacing(2) }}>
                    <TitleBox
                        mainTitle='Pages'
                        maintitleVariant='h6'
                        subTitle='All pages of the current project.'
                        subTitleVariant='subtitle2'
                        key={'projects-page-table'}
                    >
                        <Tooltip title="Add page">
                            <span>
                                <IconButton onClick={handlePageCreate} sx={{ marginLeft: theme.spacing(1) }}>
                                    <AddIcon />
                                </IconButton>
                            </span>
                        </Tooltip>
                        <Tooltip title={`Delete page${selectedIds.length > 1 ? 's' : ''}`}>
                            <span>
                                <DeleteConfirmationDialog onDeleteConfirm={handlePagesDelete}>
                                    <IconButton disabled={selectedIds.length < 1} component="label">
                                        <DeleteOutlinedIcon />
                                    </IconButton>
                                </DeleteConfirmationDialog>
                            </span>
                        </Tooltip>
                    </TitleBox>
                    <Box sx={{ height: '500px', width: '100%' }}>
                        <DataGrid
                            rows={projectPages}
                            columns={columns}
                            pageSizeOptions={[10]}
                            checkboxSelection
                            onRowSelectionModelChange={(newSM) => {
                                setSelectedIds(newSM);
                            }}
                            rowSelectionModel={selectedIds}
                            disableRowSelectionOnClick
                        />
                    </Box>
                </Paper>
            </Grid>
            <Grid item xs={4}>
                <Deployments deployments={selectedProject?.deployments ?? []} projectId={projectId} />
            </Grid>
            <Grid item xs={3}>
                <CardWidget
                    itemTitlePropName='name'
                    mainTitle='Templates'
                    subTitle='recently added'
                    items={templates}
                    maxItemAmount={4}
                    redirectUrl={`/project-detail/${projectId}/templates`}
                    navigationTooltip='Go to project templates'
                />
            </Grid>
            <Grid item xs={3}>
                <CardWidget
                    itemTitlePropName='name'
                    mainTitle='Media'
                    subTitle='across the library'
                    items={mediaFiles.filter(x => x.projectId === projectId && x.extension !== 'folder').map(x => {
                        return {
                            id: x.id, name: x.name, path: ['.otf', '.ttf', '.woff', '.woff2'].includes(x.extension) ?
                                '/letterA.webp' :
                                ['.pdf', '.mov'].includes(x.extension) ? 'Placeholder.JPG' : `root${x.url}`
                        }
                    })}
                    maxItemAmount={4}
                    redirectUrl={`/project-detail/${projectId}/media`}
                    navigationTooltip='Go to project media'
                />
            </Grid>
            <Grid item xs={3}>
                <CardWidget
                    itemTitlePropName='name'
                    mainTitle='Animations'
                    subTitle='across the library'
                    items={animations}
                    maxItemAmount={4}
                    redirectUrl={`/project-detail/${projectId}/animations`}
                    navigationTooltip='Go to project animations'
                />
            </Grid>
            <Grid item xs={3}>
                <CardWidget
                    itemTitlePropName='name'
                    mainTitle='Data'
                    subTitle='across the library'
                    items={modelDefinitions}
                    maxItemAmount={4}
                    redirectUrl={`/project-detail/${projectId}/definitions`}
                    navigationTooltip='Go to project models'
                />
            </Grid>
        </Grid>
    </>
    );
}
