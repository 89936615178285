/** @jsxImportSource @emotion/react */
import { keyframes } from "@emotion/react";
import {
  BlockAnimationKeyFrame,
  BlockAnimation,
} from "./redux/animation/types";
import { Permission } from "./redux/auth/types";
import {
  BlockStyleData,
  BlockStyle,
  BlockData,
  ScreenMode,
  BlockType,
  BlockEvent,
} from "./redux/blocks/types";
import { DynDataEntry, DynModel } from "./redux/dyndata/types";
import { ReactNode } from "react";
import { ProjectFont } from "./redux/fonts/types";
import { MediaFile } from "./redux/media/types";
import { Model, ModelPropertyType } from "./redux/model/types";

export const updateObject = (oldObject: any, updatedProps: any) => {
  return {
    ...oldObject,
    ...updatedProps,
  };
};

interface styleObj {
  styleValue: string | styleObj;
  [key: string]: string | styleObj;
}

export interface TimeStampEntry {
  createdDate?: Date;
  updatedDate?: Date;
}

export function transformAnimationsToGlobalCSS(animations: BlockAnimation[]): string {
  return animations.map(animation => {
    // Use the `GetKeyFrames` function to transform keyFrames to CSS
    const keyFramesCSS = GetFrameStyles(animation.blockAnimationKeyFrames);
    // Return the CSS for the keyframes
    const keyFrameString = `@keyframes ${animation.name} { ${GetKeyFrames(keyFramesCSS)} }`;
    return keyFrameString;
  }).join('\n');
}

function GetKeyFrames(keyFrames: Record<string, any>): string {
  return Object.entries(keyFrames).map(([key, value]) => {
    const properties = Object.entries(value).map(([property, value]) => {
      return `${property}: ${value};`;
    }).join(' ');

    return `${key} { ${properties} }`;
  }).join(' ');
}

export const GetFrameStyles = (frames: BlockAnimationKeyFrame[]) => {
  let styles: styleObj = {} as styleObj;

  frames.forEach((frame: BlockAnimationKeyFrame) => {
    let styleContainer: styleObj = {} as styleObj;
    styles[`${frame.percent}%`] = styleContainer;

    const blockStyleData = [...frame.blockStyleData].sort(
      (a: BlockStyleData, b: BlockStyleData) => a.id - b.id
    );
    for (let i: number = 0; i < blockStyleData.length; i++) {
      const styleEntry = StyleTable.find(
        (x) => x.name === blockStyleData[i].attributeKey
      );

      if (!styleEntry) {
        continue;
      }

      styleContainer[styleEntry.name] = blockStyleData[i].attributeValue;
    }
  });

  return styles;
};

export const GetStyles = (
  blockStyle: BlockStyle[] | undefined,
  media: MediaFile[]
): styleObj => {
  let styles: styleObj = {} as styleObj;
  if (blockStyle) {
    blockStyle.forEach((style: BlockStyle) => {
      if (style && style.blockStyleData?.length) {
        let styleContainer: styleObj = {} as styleObj;

        let selector = style.selector === "body" ? "#canvas" : style.selector;

        if (selector === "") {
          styleContainer = styles;
        } else if (selector in styles) {
          styleContainer = styles[selector] as styleObj;
        } else {
          styles[selector] = styleContainer;
        }

        const blockStyleData = style.blockStyleData
          .slice()
          .sort((a: BlockStyleData, b: BlockStyleData) => a.id - b.id);
        for (let i: number = 0; i < blockStyleData.length; i++) {
          const styleEntry = StyleTable.find(
            (x) => x.name === blockStyleData[i].attributeKey
          );

          if (!styleEntry) {
            continue;
          }

          if (
            styleEntry.reactName === "position" &&
            blockStyleData[i].attributeValue === "fixed"
          ) {
            styleContainer[styleEntry.reactName] = "absolute";
          } else if (
            styleEntry.reactName === "content"
          ) {
            styleContainer[styleEntry.reactName] = `'${blockStyleData[i].attributeValue}'`;
          } else if (styleEntry.reactName === "backgroundImage") {
            let values = splitCssBackgroundImages(
              blockStyleData[i].attributeValue
            );

            for (let n: number = 0; n < values.length; n++) {
              if (
                values[n].startsWith("linear-gradient") ||
                values[n].startsWith("radial-gradient")
              )
                continue;
              const mediaFile = media.find(
                (x) => x.id.toString() === values[n]
              );

              if (mediaFile) values[n] = `url('root/${mediaFile.url}')`;
            }

            styleContainer[styleEntry.reactName] = values.join(", ");
          } else {
            styleContainer[styleEntry.reactName] =
              blockStyleData[i].attributeValue;
          }
        }
      }
    });
  }
  return styles;
};

export const GetStyle = (
  canvasMode: ScreenMode,
  block: BlockData | undefined,
  media: MediaFile[],
  mainBlockStyles?: BlockStyle[]
): any => {
  let styles = {};

  const blockStyles =
    mainBlockStyles !== undefined
      ? mainBlockStyles
      : block
        ? block.templateReference != null
          ? [
            ...getReferencedBlockStyles(block.templateReference),
            ...block.blockStyles,
          ]
          : block.blockStyles
        : [];
  const screenModes = [ScreenMode.xs, ScreenMode.sm, ScreenMode.md, ScreenMode.lg, ScreenMode.xl, ScreenMode.xxl];

  for (let mode of screenModes) {
    if (screenModes.indexOf(mode) <= screenModes.indexOf(canvasMode)) {
      let currentStyle = blockStyles?.filter(x => x.mode === mode);
      let computedStyles = GetStyles(currentStyle, media);

      styles = combineStyles(styles, computedStyles);
    }
  }

  return styles;
};

const combineStyles = (firstObj: object, secondObj: object): object => {
  let newObj = { ...firstObj, ...secondObj };

  for (const [key, value] of Object.entries(newObj)) {
    if (
      Object.prototype.toString.call(value) === "[object Object]" &&
      secondObj.hasOwnProperty(key) &&
      firstObj.hasOwnProperty(key)
    ) {
      let newValue = {
        [key]: {
          ...(firstObj[key as keyof typeof firstObj] as object),
          ...(secondObj[key as keyof typeof secondObj] as object),
        },
      };

      newObj = { ...newObj, ...newValue };
    }
  }

  return newObj;
};

export const getNextAvailableValue = (
  values: number[],
  currentValue: number,
  step: number
): number => {
  let newValue = currentValue + step;
  if (newValue > 99) return 99;
  else if (newValue < 1) return 1;

  if (values.includes(newValue)) {
    return getNextAvailableValue(values, newValue, step);
  } else {
    return newValue;
  }
};

export const getReferencedProps = (block: BlockData): BlockData => {
  if (block.referenceProps && block.templateReference) {
    return getReferencedProps(block.templateReference);
  } else {
    return block;
  }
};

export const getReferencedBlockEvents = (block: BlockData): BlockEvent[] => {
  if (block.templateReference !== null) {
    const referencedEvents = getReferencedBlockEvents(block.templateReference);
    return [...referencedEvents, ...block.blockEvents];
  }

  if (block?.blockEvents) return [...block.blockEvents];

  return [];
};

export const getReferencedBlockStyles = (
  blockData: BlockData
): BlockStyle[] => {
  if (blockData.templateReference !== null) {
    const referenceStyles = getReferencedBlockStyles(
      blockData.templateReference
    );
    return [...referenceStyles, ...blockData.blockStyles];
  }

  if (blockData?.blockStyles) return [...blockData.blockStyles];

  return [];
};
type Attributes = {
  common: string[];
  aria?: string[];
};

type AttributeData = Record<BlockType, Attributes>;

export interface WithEmotionProps {
  children?: ReactNode;
  [key: string]: any;
}

export type CustomComponentsType = Partial<{
  [key in BlockType]: (props: any) => JSX.Element;
}>;

export function getAllObjects(
  obj: { [key: string]: any },
  parentProperty?: string
): string[] {
  let result: string[] = [];

  const extractObjects = (
    obj: { [key: string]: any },
    parentProperty?: string
  ) => {
    for (const key in obj) {
      if (
        typeof obj[key] === "object" &&
        !Array.isArray(obj[key]) &&
        obj[key] !== null
      ) {
        const newKey = parentProperty ? `${parentProperty}.${key}` : key;
        result.push(newKey);
        extractObjects(obj[key], newKey);
      }
    }
  };

  extractObjects(obj, parentProperty);

  return result;
}

export function getAllProperties(
  obj: { [key: string]: any },
  parentProperty?: string
): string[] {
  let result: string[] = [];

  for (const key in obj) {
    if (Array.isArray(obj[key])) {
      continue;
    }

    const newKey = parentProperty ? `${parentProperty}.${key}` : key;
    if (
      typeof obj[key] === "object" &&
      obj[key] !== null &&
      !Array.isArray(obj[key])
    ) {
      result = result.concat(getAllProperties(obj[key], newKey));
    } else {
      result.push(newKey);
    }
  }

  return result;
}

export const BlockAttributeTypes: AttributeData = {
  A: {
    common: ["href", "target", "rel", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  FORM: {
    common: ["action", "method", "enctype", "autocomplete", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  BUTTON: {
    common: ["type", "autofocus", "disabled", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  INPUT: {
    common: [
      "type",
      "name",
      "value",
      "placeholder",
      "disabled",
      "readonly",
      "required",
      'aria-label',
      'aria-labelledby',
      'aria-hidden',
      'aria-live',
      'aria-role'
    ],
  },
  SELECT: {
    common: ["name", "multiple", "required", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  TEXTAREA: {
    common: ["name", "placeholder", "disabled", "readonly", "required", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  UL: {
    common: ["type", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  OL: {
    common: ["reversed", "start", "type", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  LI: {
    common: ["value", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  DIV: {
    common: ["contenteditable", "tabindex", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  P: {
    common: ["align", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  H1: {
    common: ["align", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  H2: {
    common: ["align", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  H3: {
    common: ["align", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  H4: {
    common: ["align", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  H5: {
    common: ["align", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  H6: {
    common: ["align", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  IMG: {
    common: ["src", "alt", "width", "height", "usemap", "ismap", "crossorigin", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role', 'sizes', 'loading'],
  },
  TABLE: {
    common: ["border", "cellpadding", "cellspacing", "width", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  TR: {
    common: ["align", "valign", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  TD: {
    common: ["align", "valign", "colspan", "rowspan", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  TH: {
    common: ["align", "valign", "colspan", "rowspan", "scope", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  VIDEO: {
    common: [
      "src",
      "poster",
      "preload",
      "autoplay",
      "controls",
      "loop",
      "muted",
      "width",
      "height",
      'aria-label',
      'aria-labelledby',
      'aria-hidden',
      'aria-live',
      'aria-role'
    ],
  },
  AUDIO: {
    common: ["src", "preload", "autoplay", "controls", "loop", "muted", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  SPAN: {
    common: ["contenteditable", "dir", "lang", "tabindex", "title", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  TBODY: {
    common: ["align", "valign", "char", "charoff", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  THEAD: {
    common: ["align", "valign", "char", "charoff", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  TFOOT: {
    common: ["align", "valign", "char", "charoff", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  OPTION: {
    common: ["value", "label", "selected", "disabled", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  GRID: {
    common: [],
  },
  GRIDITEM: {
    common: ["xs", "sm", "md", "lg", "xl"],
  },
  LOOP: {
    common: ["data-set", 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  CONTAINER: {
    common: ['aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  SECTION: {
    common: ['aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  NAV: {
    common: ['aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  FOOTER: {
    common: ['aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  HEADER: {
    common: ['aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  MAIN: {
    common: ['aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  ARTICLE: {
    common: ['aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  LABEL: {
    common: ['htmlFor', 'form', 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  OUTPUT: {
    common: ['htmlFor', 'form', 'name', 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  METER: {
    common: ['value', 'min', 'max', 'low', 'high', 'optimum', 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
  PROGRESS: {
    common: ['value', 'max', 'aria-label', 'aria-labelledby', 'aria-hidden', 'aria-live', 'aria-role'],
  },
};

export const DESKTOP = "DESKTOP";
export const TABLET = "TABLET";
export const PHONE = "PHONE";
interface Style {
  name: string;
  reactName: string;
  description: string;
  inherited: boolean;
  defaultValue: string;
  relatedAttributes?: string[];
  relatedValues?: { actual: string; alias: string }[];
  tagDependentDefaults?: { type: BlockType; default: string }[];
}

export const StyleTable: Style[] = [
  {
    name: "align-content",
    reactName: "alignContent",
    description:
      "Specifies the alignment between the lines inside a flexible container when the items do not use all available space",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "align-items",
    reactName: "alignItems",
    description:
      "Specifies the alignment for items inside a flexible container",
    inherited: false,
    defaultValue: "flex-start",
  },
  {
    name: "align-self",
    reactName: "alignSelf",
    description:
      "Specifies the alignment for selected items inside a flexible container",
    inherited: false,
    defaultValue: "flex-start",
  },
  {
    name: "all",
    reactName: "all",
    description: "Resets all properties (except unicode-bidi and direction)",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation",
    reactName: "animation",
    description: "A shorthand property for all the animation-* properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-delay",
    reactName: "animationDelay",
    description: "Specifies a delay for the start of an animation",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-direction",
    reactName: "animationDirection",
    description:
      "Specifies whether an animation should be played forwards, backwards or in alternate cycles",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-duration",
    reactName: "animationDuration",
    description:
      "Specifies how long an animation should take to complete one cycle",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-fill-mode",
    reactName: "animationFillMode",
    description:
      "Specifies a style for the element when the animation is not playing (before it starts, after it ends, or both)",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-iteration-count",
    reactName: "animationIterationCount",
    description: "Specifies the number of times an animation should be played",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-name",
    reactName: "animationName",
    description: "Specifies a name for the @keyframes animation",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-play-state",
    reactName: "animationPlayState",
    description: "Specifies whether the animation is running or paused",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "animation-timing-function",
    reactName: "animationTimingFunction",
    description: "Specifies the speed curve of an animation",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "aspect-ratio",
    reactName: "aspectRatio",
    description: "The value is a ratio expressed as width/height where both width and height are positive numbers that represent the proportional relationship between the width and height of the box.",
    inherited: false,
    defaultValue: "auto",
  },
  {
    name: "backface-visibility",
    reactName: "backfaceVisibility",
    description:
      "Defines whether or not the back face of an element should be visible when facing the user",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "background",
    reactName: "background",
    description: "A shorthand property for all the background-* properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "background-attachment",
    reactName: "backgroundAttachment",
    description:
      "Sets whether a background image scrolls with the rest of the page, or is fixed",
    inherited: false,
    defaultValue: "scroll",
  },
  {
    name: "background-blend-mode",
    reactName: "backgroundBlendMode",
    description:
      "Specifies the blending mode of each background layer (color/image)",
    inherited: false,
    defaultValue: "normal",
  },
  {
    name: "background-clip",
    reactName: "backgroundClip",
    description:
      "Defines how far the background (color or image) should extend within an element",
    inherited: false,
    defaultValue: "border-box",
  },
  {
    name: "background-color",
    reactName: "backgroundColor",
    description: "Specifies the background color of an element",
    inherited: false,
    defaultValue: "#000000",
  },
  {
    name: "background-image",
    reactName: "backgroundImage",
    description: "Specifies one or more background images for an element",
    inherited: false,
    defaultValue: "url('')",
  },
  {
    name: "background-origin",
    reactName: "backgroundOrigin",
    description: "Specifies the origin position of a background image",
    inherited: false,
    defaultValue: "padding-box",
  },
  {
    name: "background-position",
    reactName: "backgroundPosition",
    description: "Specifies the position of a background image",
    inherited: false,
    defaultValue: "left top",
  },
  {
    name: "background-repeat",
    reactName: "backgroundRepeat",
    description: "Sets if/how a background image will be repeated",
    inherited: false,
    defaultValue: "repeat",
  },
  {
    name: "background-size",
    reactName: "backgroundSize",
    description: "Specifies the size of the background images",
    inherited: false,
    defaultValue: "auto auto",
  },
  {
    name: "border",
    reactName: "border",
    description:
      "A shorthand property for border-width, border-style and border-color",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "border-bottom",
    reactName: "borderBottom",
    description:
      "A shorthand property for border-bottom-width, border-bottom-style and border-bottom-color",
    inherited: false,
    relatedAttributes: ["border-block-end"],
    defaultValue: "",
  },
  {
    name: "border-bottom-color",
    reactName: "borderBottomColor",
    description: "Sets the color of the bottom border",
    inherited: false,
    defaultValue: "#000000",
  },
  {
    name: "border-bottom-left-radius",
    reactName: "borderBottomLeftRadius",
    description: "Defines the radius of the border of the bottom-left corner",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-bottom-right-radius",
    reactName: "borderBottomRightRadius",
    description: "Defines the radius of the border of the bottom-right corner",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-bottom-style",
    reactName: "borderBottomStyle",
    description: "Sets the style of the bottom border",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "border-bottom-width",
    reactName: "borderBottomWidth",
    description: "Sets the width of the bottom border",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-collapse",
    reactName: "borderCollapse",
    description:
      "Sets whether table borders should collapse into a single border or be separated",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "border-color",
    reactName: "borderColor",
    description: "Sets the color of the four borders",
    inherited: false,
    defaultValue: "#000000",
  },
  {
    name: "border-image",
    reactName: "borderImage",
    description: "A shorthand property for all the border-image-* properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "border-image-outset",
    reactName: "borderImageOutset",
    description:
      "Specifies the amount by which the border image area extends beyond the border box",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "border-image-repeat",
    reactName: "borderImageRepeat",
    description:
      "Specifies whether the border image should be repeated, rounded or stretched",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "border-image-slice",
    reactName: "borderImageSlice",
    description: "Specifies how to slice the border image",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "border-image-source",
    reactName: "borderImageSource",
    description: "Specifies the path to the image to be used as a border",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "border-image-width",
    reactName: "borderImageWidth",
    description: "Specifies the width of the border image",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-left",
    reactName: "borderLeft",
    description: "A shorthand property for all the border-left-* properties",
    inherited: false,
    relatedAttributes: ["border-inline-start"],
    defaultValue: "",
  },
  {
    name: "border-left-color",
    reactName: "borderLeftColor",
    description: "Sets the color of the left border",
    inherited: false,
    defaultValue: "#000000",
  },
  {
    name: "border-left-style",
    reactName: "borderLeftStyle",
    description: "Sets the style of the left border",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "border-left-width",
    reactName: "borderLeftWidth",
    description: "Sets the width of the left border",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-radius",
    reactName: "borderRadius",
    description: "A shorthand property for the four border-*-radius properties",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-right",
    reactName: "borderRight",
    description: "A shorthand property for all the border-right-* properties",
    inherited: false,
    relatedAttributes: ["border-inline-end"],
    defaultValue: "",
  },
  {
    name: "border-right-color",
    reactName: "borderRightColor",
    description: "Sets the color of the right border",
    inherited: false,
    defaultValue: "#000000",
  },
  {
    name: "border-right-style",
    reactName: "borderRightStyle",
    description: "Sets the style of the right border",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "border-right-width",
    reactName: "borderRightWidth",
    description: "Sets the width of the right border",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-spacing",
    reactName: "borderSpacing",
    description: "Sets the distance between the borders of adjacent cells",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "border-style",
    reactName: "borderStyle",
    description: "Sets the style of the four borders",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "border-top",
    reactName: "borderTop",
    description:
      "A shorthand property for border-top-width, border-top-style and border-top-color",
    inherited: false,
    relatedAttributes: ["border-block-start"],
    defaultValue: "",
  },
  {
    name: "border-top-color",
    reactName: "borderTopColor",
    description: "Sets the color of the top border",
    inherited: false,
    defaultValue: "#000000",
  },
  {
    name: "border-top-left-radius",
    reactName: "borderTopLeftRadius",
    description: "Defines the radius of the border of the top-left corner",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-top-right-radius",
    reactName: "borderTopRightRadius",
    description: "Defines the radius of the border of the top-right corner",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-top-style",
    reactName: "borderTopStyle",
    description: "Sets the style of the top border",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "border-top-width",
    reactName: "borderTopWidth",
    description: "Sets the width of the top border",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "border-width",
    reactName: "borderWidth",
    description: "Sets the width of the four borders",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "bottom",
    reactName: "bottom",
    description:
      "Sets the elements position, from the bottom of its parent element",
    inherited: false,
    relatedAttributes: ["inset-block-end"],
    defaultValue: "auto",
  },
  {
    name: "box-decoration-break",
    reactName: "boxDecorationBreak",
    description:
      "Sets the behavior of the background and border of an element at page-break, or, for in-line elements, at line-break.",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "box-shadow",
    reactName: "boxShadow",
    description: "Attaches one or more shadows to an element",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "box-sizing",
    reactName: "boxSizing",
    description:
      "Defines how the width and height of an element are calculated: should they include padding and borders, or not",
    inherited: false,
    defaultValue: "content-box",
  },
  {
    name: "break-after",
    reactName: "breakAfter",
    description:
      "Specifies whether or not a page-, column-, or region-break should occur after the specified element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "break-before",
    reactName: "breakBefore",
    description:
      "Specifies whether or not a page-, column-, or region-break should occur before the specified element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "break-inside",
    reactName: "breakInside",
    description:
      "Specifies whether or not a page-, column-, or region-break should occur inside the specified element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "caption-side",
    reactName: "captionSide",
    description: "Specifies the placement of a table caption",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "caret-color",
    reactName: "caretColor",
    description:
      "Specifies the color of the cursor (caret) in inputs, textareas, or any element that is editable",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "@charset",
    reactName: "@charset",
    description: "Specifies the character encoding used in the style sheet",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "clear",
    reactName: "clear",
    description:
      "Specifies on which sides of an element floating elements are not allowed to float",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "clip",
    reactName: "clip",
    description: "Clips an absolutely positioned element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "color",
    reactName: "color",
    description: "Sets the color of text",
    inherited: true,
    defaultValue: "#000000",
  },
  {
    name: "column-count",
    reactName: "columnCount",
    description:
      "Specifies the number of columns an element should be divided into",
    inherited: false,
    defaultValue: "auto",
  },
  {
    name: "column-fill",
    reactName: "columnFill",
    description: "Specifies how to fill columns, balanced or not",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "column-gap",
    reactName: "columnGap",
    description: "Specifies the gap between the columns",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "column-rule",
    reactName: "columnRule",
    description: "A shorthand property for all the column-rule-* properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "column-rule-color",
    reactName: "columnRuleColor",
    description: "Specifies the color of the rule between columns",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "column-rule-style",
    reactName: "columnRuleStyle",
    description: "Specifies the style of the rule between columns",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "column-rule-width",
    reactName: "columnRuleWidth",
    description: "Specifies the width of the rule between columns",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "column-span",
    reactName: "columnSpan",
    description: "Specifies how many columns an element should span across",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "column-width",
    reactName: "columnWidth",
    description: "Specifies the column width",
    inherited: false,
    defaultValue: "auto",
  },
  {
    name: "columns",
    reactName: "columns",
    description: "A shorthand property for column-width and column-count",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "content",
    reactName: "content",
    description:
      "Used with the :before and :after pseudo-elements, to insert generated content",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "counter-increment",
    reactName: "counterIncrement",
    description: "Increases or decreases the value of one or more CSS counters",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "counter-reset",
    reactName: "counterReset",
    description: "Creates or resets one or more CSS counters",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "cursor",
    reactName: "cursor",
    description:
      "Specifies the mouse cursor to be displayed when pointing over an element",
    inherited: true,
    defaultValue: "auto",
  },
  {
    name: "direction",
    reactName: "direction",
    description: "Specifies the text direction/writing direction",
    inherited: true,
    defaultValue: "ltr",
  },
  {
    name: "display",
    reactName: "display",
    description: "Specifies how a certain HTML element should be displayed",
    inherited: false,
    defaultValue: "block",
    tagDependentDefaults: [
      { type: BlockType.SPAN, default: "inline" },
      { type: BlockType.A, default: "inline" },
      { type: BlockType.LABEL, default: "inline" },
    ],
  },
  {
    name: "empty-cells",
    reactName: "emptyCells",
    description:
      "Specifies whether or not to display borders and background on empty cells in a table",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "filter",
    reactName: "filter",
    description:
      "Defines effects (e.g. blurring or color shifting) on an element before the element is displayed",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "flex",
    reactName: "flex",
    description:
      "A shorthand property for the flex-grow, flex-shrink, and the flex-basis properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "flex-basis",
    reactName: "flexBasis",
    description: "Specifies the initial length of a flexible item",
    inherited: false,
    defaultValue: "auto",
  },
  {
    name: "flex-direction",
    reactName: "flexDirection",
    description: "Specifies the direction of the flexible items",
    inherited: false,
    defaultValue: "row",
  },
  {
    name: "flex-flow",
    reactName: "flexFlow",
    description:
      "A shorthand property for the flex-direction and the flex-wrap properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "flex-grow",
    reactName: "flexGrow",
    description: "Specifies how much the item will grow relative to the rest",
    inherited: false,
    defaultValue: "0",
  },
  {
    name: "flex-shrink",
    reactName: "flexShrink",
    description: "Specifies how the item will shrink relative to the rest",
    inherited: false,
    defaultValue: "1",
  },
  {
    name: "flex-wrap",
    reactName: "flexWrap",
    description: "Specifies whether the flexible items should wrap or not",
    inherited: false,
    defaultValue: "nowrap",
  },
  {
    name: "float",
    reactName: "float",
    description: "Specifies whether or not a box should float",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "font",
    reactName: "font",
    description:
      "A shorthand property for the font-style, font-variant, font-weight, font-size/line-height, and the font-family properties",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "@font-face",
    reactName: "@fontFace",
    description:
      "A rule that allows websites to download and use fonts other than the 'web-safe' fonts",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-family",
    reactName: "fontFamily",
    description: "Specifies the font family for text",
    inherited: true,
    defaultValue: "Arial",
  },
  {
    name: "font-feature-settings",
    reactName: "fontFeatureSettings",
    description:
      "Allows control over advanced typographic features in OpenType fonts",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "@font-feature-values",
    reactName: "@fontFeatureValues",
    description:
      "Allows authors to use a common name in font-variant-alternate for feature activated differently in OpenType",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-kerning",
    reactName: "fontKerning",
    description:
      "Controls the usage of the kerning information (how letters are spaced)",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-language-override",
    reactName: "fontLanguageOverride",
    description: "Controls the usage of language-specific glyphs in a typeface",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-size",
    reactName: "fontSize",
    description: "Specifies the font size of text",
    inherited: true,
    defaultValue: "0px",
  },
  {
    name: "font-size-adjust",
    reactName: "fontSizeAdjust",
    description: "Preserves the readability of text when font fallback occurs",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "font-stretch",
    reactName: "fontStretch",
    description:
      "Selects a normal, condensed, or expanded face from a font family",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "font-style",
    reactName: "fontStyle",
    description: "Specifies the font style for text",
    inherited: true,
    defaultValue: "normal",
  },
  {
    name: "font-synthesis",
    reactName: "fontSynthesis",
    description:
      "Controls which missing typefaces (bold or italic) may be synthesized by the browser",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-variant",
    reactName: "fontVariant",
    description:
      "Specifies whether or not a text should be displayed in a small-caps font",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "font-variant-alternates",
    reactName: "fontVariantAlternates",
    description:
      "Controls the usage of alternate glyphs associated to alternative names defined in @font-feature-values",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-variant-caps",
    reactName: "fontVariantCaps",
    description: "Controls the usage of alternate glyphs for capital letters",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-variant-east-asian",
    reactName: "fontVariantEastAsian",
    description:
      "Controls the usage of alternate glyphs for East Asian scripts (e.g Japanese and Chinese)",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-variant-ligatures",
    reactName: "fontVariantLigatures",
    description:
      "Controls which ligatures and contextual forms are used in textual content of the elements it applies to",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-variant-numeric",
    reactName: "fontVariantNumeric",
    description:
      "Controls the usage of alternate glyphs for numbers, fractions, and ordinal markers",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-variant-position",
    reactName: "fontVariantPosition",
    description:
      "Controls the usage of alternate glyphs of smaller size positioned as superscript or subscript regarding the baseline of the font",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "font-weight",
    reactName: "fontWeight",
    description: "Specifies the weight of a font",
    inherited: true,
    defaultValue: "400",
  },
  {
    name: "grid",
    reactName: "grid",
    description:
      "A shorthand property for the grid-template-rows, grid-template-columns, grid-template-areas, grid-auto-rows, grid-auto-columns, and the grid-auto-flow properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-area",
    reactName: "gridArea",
    description:
      "Either specifies a name for the grid item, or this property is a shorthand property for the grid-row-start, grid-column-start, grid-row-end, and grid-column-end properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-auto-columns",
    reactName: "gridAutoColumns",
    description: "Specifies a default column size",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-auto-flow",
    reactName: "gridAutoFlow",
    description: "Specifies how auto-placed items are inserted in the grid",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-auto-rows",
    reactName: "gridAutoRows",
    description: "Specifies a default row size",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-column",
    reactName: "gridColumn",
    description:
      "A shorthand property for the grid-column-start and the grid-column-end properties",
    inherited: false,
    defaultValue: "1",
  },
  {
    name: "grid-column-end",
    reactName: "gridColumnEnd",
    description: "Specifies where to end the grid item",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-column-gap",
    reactName: "gridColumnGap",
    description: "Specifies the size of the gap between columns",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-column-start",
    reactName: "gridColumnStart",
    description: "Specifies where to start the grid item",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-gap",
    reactName: "gridGap",
    description:
      "A shorthand property for the grid-row-gap and grid-column-gap properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-row",
    reactName: "gridRow",
    description:
      "A shorthand property for the grid-row-start and the grid-row-end properties",
    inherited: false,
    defaultValue: "1",
  },
  {
    name: "grid-row-end",
    reactName: "gridRowEnd",
    description: "Specifies where to end the grid item",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-row-gap",
    reactName: "gridRowGap",
    description: "Specifies the size of the gap between rows",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-row-start",
    reactName: "gridRowStart",
    description: "Specifies where to start the grid item",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-template",
    reactName: "gridTemplate",
    description:
      "A shorthand property for the grid-template-rows, grid-template-columns and grid-areas properties",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-template-areas",
    reactName: "gridTemplateAreas",
    description:
      "Specifies how to display columns and rows, using named grid items",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-template-columns",
    reactName: "gridTemplateColumns",
    description:
      "Specifies the size of the columns, and how many columns in a grid layout",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "grid-template-rows",
    reactName: "gridTemplateRows",
    description: "Specifies the size of the rows in a grid layout",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "hanging-punctuation",
    reactName: "hangingPunctuation",
    description:
      "Specifies whether a punctuation character may be placed outside the line box",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "height",
    reactName: "height",
    description: "Sets the height of an element",
    inherited: false,
    relatedAttributes: ["block-size"],
    defaultValue: "auto",
  },
  {
    name: "hyphens",
    reactName: "hyphens",
    description: "Sets how to split words to improve the layout of paragraphs",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "image-rendering",
    reactName: "imageRendering",
    description:
      "Gives a hint to the browser about what aspects of an image are most important to preserve when the image is scaled",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "@import",
    reactName: "@import",
    description: "Allows you to import a style sheet into another style sheet",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "isolation",
    reactName: "isolation",
    description:
      "Defines whether an element must create a new stacking content",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "justify-content",
    reactName: "justifyContent",
    description:
      "Specifies the alignment between the items inside a flexible container when the items do not use all available space",
    inherited: false,
    defaultValue: "flex-start",
  },
  {
    name: "justify-self",
    reactName: "justifySelf",
    description:
      "Aligns a grid item within its grid cell in the inline direction",
    inherited: false,
    defaultValue: "flex-start",
  },
  {
    name: "@keyframes",
    reactName: "@keyframes",
    description: "Specifies the animation code",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "left",
    reactName: "left",
    description: "Specifies the left position of a positioned element",
    inherited: false,
    relatedAttributes: ["inset-inline-start"],
    defaultValue: "auto",
  },
  {
    name: "letter-spacing",
    reactName: "letterSpacing",
    description:
      "Increases or decreases the space between characters in a text",
    inherited: true,
    defaultValue: "0px",
  },
  {
    name: "line-break",
    reactName: "lineBreak",
    description: "Specifies how/if to break lines",
    inherited: false,
    defaultValue: "normal",
  },
  {
    name: "line-height",
    reactName: "lineHeight",
    description: "Sets the line height",
    inherited: true,
    defaultValue: "0px",
  },
  {
    name: "list-style",
    reactName: "listStyle",
    description: "Sets all the properties for a list in one declaration",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "list-style-image",
    reactName: "listStyleImage",
    description: "Specifies an image as the list-item marker",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "list-style-position",
    reactName: "listStylePosition",
    description:
      "Specifies the position of the list-item markers (bullet points)",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "list-style-type",
    reactName: "listStyleType",
    description: "Specifies the type of list-item marker",
    inherited: true,
    defaultValue: "disc",
  },
  {
    name: "margin",
    reactName: "margin",
    description: "Sets all the margin properties in one declaration",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "margin-bottom",
    reactName: "marginBottom",
    description: "Sets the bottom margin of an element",
    inherited: false,
    relatedAttributes: ["margin-block-end"],
    defaultValue: "0px",
  },
  {
    name: "margin-left",
    reactName: "marginLeft",
    description: "Sets the left margin of an element",
    inherited: false,
    relatedAttributes: ["margin-inline-start"],
    defaultValue: "0px",
  },
  {
    name: "margin-right",
    reactName: "marginRight",
    description: "Sets the right margin of an element",
    inherited: false,
    relatedAttributes: ["margin-inline-end"],
    defaultValue: "0px",
  },
  {
    name: "margin-top",
    reactName: "marginTop",
    description: "Sets the top margin of an element",
    inherited: false,
    relatedAttributes: ["margin-block-start"],
    defaultValue: "0px",
  },
  {
    name: "mask",
    reactName: "mask",
    description:
      "Hides an element by masking or clipping the image at specific places",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "mask-type",
    reactName: "maskType",
    description:
      "Specifies whether a mask element is used as a luminance or an alpha mask",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "max-height",
    reactName: "maxHeight",
    description: "Sets the maximum height of an element",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "max-width",
    reactName: "maxWidth",
    description: "Sets the maximum width of an element",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "@media",
    reactName: "@media",
    description: "Sets the style rules for different media types/devices/sizes",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "min-height",
    reactName: "minHeight",
    description: "Sets the minimum height of an element",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "min-width",
    reactName: "minWidth",
    description: "Sets the minimum width of an element",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "mix-blend-mode",
    reactName: "mixBlendMode",
    description:
      "Specifies how an element's content should blend with its direct parent background",
    inherited: false,
    defaultValue: "normal",
  },
  {
    name: "object-fit",
    reactName: "objectFit",
    description:
      "Specifies how the contents of a replaced element should be fitted to the box established by its used height and width",
    inherited: false,
    defaultValue: "fill",
  },
  {
    name: "object-position",
    reactName: "objectPosition",
    description:
      "Specifies the alignment of the replaced element inside its box",
    inherited: false,
    defaultValue: "center",
  },
  {
    name: "opacity",
    reactName: "opacity",
    description: "Sets the opacity level for an element",
    inherited: false,
    defaultValue: "1",
  },
  {
    name: "order",
    reactName: "order",
    description: "Sets the order of the flexible item, relative to the rest",
    inherited: false,
    defaultValue: "0",
  },
  {
    name: "orphans",
    reactName: "orphans",
    description:
      "Sets the minimum number of lines that must be left at the bottom of a page when a page break occurs inside an element",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "outline",
    reactName: "outline",
    description:
      "A shorthand property for the outline-width, outline-style, and the outline-color properties",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "outline-color",
    reactName: "outlineColor",
    description: "Sets the color of an outline",
    inherited: false,
    defaultValue: "#000000",
  },
  {
    name: "outline-offset",
    reactName: "outlineOffset",
    description: "Offsets an outline, and draws it beyond the border edge",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "outline-style",
    reactName: "outlineStyle",
    description: "Sets the style of an outline",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "outline-width",
    reactName: "outlineWidth",
    description: "Sets the width of an outline",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "overflow",
    reactName: "overflow",
    description: "Specifies what happens if content overflows an element's box",
    inherited: false,
    defaultValue: "visible",
  },
  {
    name: "overflow-wrap",
    reactName: "overflowWrap",
    description:
      "Specifies whether or not the browser may break lines within words in order to prevent overflow (when a string is too long to fit its containing box)",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "overflow-x",
    reactName: "overflowX",
    description:
      "Specifies whether or not to clip the left/right edges of the content, if it overflows the element's content area",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "overflow-y",
    reactName: "overflowY",
    description:
      "Specifies whether or not to clip the top/bottom edges of the content, if it overflows the element's content area",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "padding",
    reactName: "padding",
    description: "A shorthand property for all the padding-* properties",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "padding-bottom",
    reactName: "paddingBottom",
    description: "Sets the bottom padding of an element",
    inherited: false,
    relatedAttributes: ["padding-block-end"],
    defaultValue: "0px",
  },
  {
    name: "padding-left",
    reactName: "paddingLeft",
    description: "Sets the left padding of an element",
    inherited: false,
    relatedAttributes: ["padding-inline-start"],
    defaultValue: "0px",
  },
  {
    name: "padding-right",
    reactName: "paddingRight",
    description: "Sets the right padding of an element",
    inherited: false,
    relatedAttributes: ["padding-inline-end"],
    defaultValue: "0px",
  },
  {
    name: "padding-top",
    reactName: "paddingTop",
    description: "Sets the top padding of an element",
    inherited: false,
    relatedAttributes: ["padding-block-start"],
    defaultValue: "0px",
  },
  {
    name: "page-break-after",
    reactName: "pageBreakAfter",
    description: "Sets the page-break behavior after an element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "page-break-before",
    reactName: "pageBreakBefore",
    description: "Sets the page-break behavior before an element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "page-break-inside",
    reactName: "pageBreakInside",
    description: "Sets the page-break behavior inside an element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "perspective",
    reactName: "perspective",
    description: "Gives a 3D-positioned element some perspective",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "perspective-origin",
    reactName: "perspectiveOrigin",
    description:
      "Defines at which position the user is looking at the 3D-positioned element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "pointer-events",
    reactName: "pointerEvents",
    description: "Defines whether or not an element reacts to pointer events",
    inherited: false,
    defaultValue: "auto",
  },
  {
    name: "position",
    reactName: "position",
    description:
      "Specifies the type of positioning method used for an element (static, relative, absolute or fixed)",
    inherited: false,
    defaultValue: "static",
  },
  {
    name: "quotes",
    reactName: "quotes",
    description: "Sets the type of quotation marks for embedded quotations",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "resize",
    reactName: "resize",
    description: "Defines if (and how) an element is resizable by the user",
    inherited: false,
    defaultValue: "none",
    tagDependentDefaults: [{ type: BlockType.TEXTAREA, default: "both" }],
  },
  {
    name: "right",
    reactName: "right",
    description: "Specifies the right position of a positioned element",
    inherited: false,
    relatedAttributes: ["inset-inline-end"],
    defaultValue: "auto",
  },
  {
    name: "row-gap",
    reactName: "rowGap",
    description: "Specifies the gap between the rows",
    inherited: false,
    defaultValue: "0px",
  },
  {
    name: "scroll-behavior",
    reactName: "scrollBehavior",
    description:
      "Specifies whether to smoothly animate the scroll position in a scrollable box, instead of a straight jump",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "tab-size",
    reactName: "tabSize",
    description: "Specifies the width of a tab character",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "table-layout",
    reactName: "tableLayout",
    description:
      "Defines the algorithm used to lay out table cells, rows, and columns",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "text-align",
    reactName: "textAlign",
    description: "Specifies the horizontal alignment of text",
    inherited: true,
    relatedValues: [
      { actual: "left", alias: "start" },
      { actual: "right", alias: "end" },
    ],
    defaultValue: "left",
  },
  {
    name: "text-align-last",
    reactName: "textAlignLast",
    description:
      "Describes how the last line of a block or a line right before a forced line break is aligned when text-align is 'justify'",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "text-combine-upright",
    reactName: "textCombineUpright",
    description:
      "Specifies the combination of multiple characters into the space of a single character",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "text-decoration",
    reactName: "textDecoration",
    description: "Specifies the decoration added to text",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "text-decoration-color",
    reactName: "textDecorationColor",
    description: "Specifies the color of the text-decoration",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "text-decoration-line",
    reactName: "textDecorationLine",
    description: "Specifies the type of line in a text-decoration",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "text-decoration-style",
    reactName: "textDecorationStyle",
    description: "Specifies the style of the line in a text decoration",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "text-indent",
    reactName: "textIndent",
    description: "Specifies the indentation of the first line in a text-block",
    inherited: true,
    defaultValue: "0px",
  },
  {
    name: "text-justify",
    reactName: "textJustify",
    description:
      "Specifies the justification method used when text-align is 'justify'",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "text-orientation",
    reactName: "textOrientation",
    description: "Defines the orientation of the text in a line",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "text-overflow",
    reactName: "textOverflow",
    description:
      "Specifies what should happen when text overflows the containing element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "text-shadow",
    reactName: "textShadow",
    description: "Adds shadow to text",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "text-transform",
    reactName: "textTransform",
    description: "Controls the capitalization of text",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "text-underline-position",
    reactName: "textUnderlinePosition",
    description:
      "Specifies the position of the underline which is set using the text-decoration property",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "top",
    reactName: "top",
    description: "Specifies the top position of a positioned element",
    inherited: false,
    relatedAttributes: ["inset-block-start"],
    defaultValue: "auto",
  },
  {
    name: "transform",
    reactName: "transform",
    description: "Applies a 2D or 3D transformation to an element",
    inherited: false,
    defaultValue: "none",
  },
  {
    name: "transform-origin",
    reactName: "transformOrigin",
    description: "Allows you to change the position on transformed elements",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "transform-style",
    reactName: "transformStyle",
    description: "Specifies how nested elements are rendered in 3D space",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "transition",
    reactName: "transition",
    description: "A shorthand property for all the transition-* properties",
    inherited: false,
    defaultValue: "all 0s ease 0s",
  },
  {
    name: "transition-delay",
    reactName: "transitionDelay",
    description: "Specifies when the transition effect will start",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "transition-duration",
    reactName: "transitionDuration",
    description:
      "Specifies how many seconds or milliseconds a transition effect takes to complete",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "transition-property",
    reactName: "transitionProperty",
    description:
      "Specifies the name of the CSS property the transition effect is for",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "transition-timing-function",
    reactName: "transitionTimingFunction",
    description: "Specifies the speed curve of the transition effect",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "unicode-bidi",
    reactName: "unicodeBidi",
    description:
      "Used together with the direction property to set or return whether the text should be overridden to support multiple languages in the same document",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "user-select",
    reactName: "userSelect",
    description:
      "Sets the elements position, from the bottom of its parent element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "vertical-align",
    reactName: "verticalAlign",
    description: "Sets the vertical alignment of an element",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "visibility",
    reactName: "visibility",
    description: "Specifies whether or not an element is visible",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "white-space",
    reactName: "whiteSpace",
    description: "Specifies how white-space inside an element is handled",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "widows",
    reactName: "widows",
    description:
      "Sets the minimum number of lines that must be left at the top of a page when a page break occurs inside an element",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "width",
    reactName: "width",
    description: "Sets the width of an element",
    inherited: false,
    relatedAttributes: ["inline-size"],
    defaultValue: "auto",
  },
  {
    name: "word-break",
    reactName: "wordBreak",
    description:
      "Specifies how words should break when reaching the end of a line",
    inherited: true,
    defaultValue: "normal",
  },
  {
    name: "word-spacing",
    reactName: "wordSpacing",
    description: "Increases or decreases the space between words in a text",
    inherited: true,
    defaultValue: "",
  },
  {
    name: "word-wrap",
    reactName: "wordWrap",
    description:
      "Allows long, unbreakable words to be broken and wrap to the next line",
    inherited: true,
    defaultValue: "normal",
  },
  {
    name: "writing-mode",
    reactName: "writingMode",
    description:
      "Specifies whether lines of text are laid out horizontally or vertically",
    inherited: false,
    defaultValue: "",
  },
  {
    name: "z-index",
    reactName: "zIndex",
    description: "Sets the stack order of a positioned element",
    inherited: false,
    defaultValue: "0",
  },
];

export function uniqueFilter<T>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function getFormattedDate(
  d: Date | number | string | undefined,
  includeTime?: boolean,
  defaultValue = "Never"
) {
  if (!d) return defaultValue;

  d = new Date(d);

  const strDate = "D.M.Y"
    .replace("D", d.getDate().toString())
    .replace("M", (d.getMonth() + 1).toString())
    .replace("Y", d.getFullYear().toString());

  if (includeTime) {
    const minutes = d.getMinutes();
    const minAsStr = minutes > 9 ? minutes.toString() : `0${minutes}`;

    return `${strDate} ${d.getHours()}:${minAsStr}`;
  }
  return strDate;
}

const permissionTypes = [
  "MEDIA",
  "ANIMATIONS",
  "ACTION_SEQUENCE",
  "TEMPLATE",
  "USER",
  "PROJECT",
] as const;
type PermissionType = (typeof permissionTypes)[number];

function getSinglePermissionDescription(
  type: PermissionType,
  permissions: Permission[]
) {
  let description = `${type} (`;
  const mediaP = permissions.filter((x) => x.startsWith(type));

  let mediaPermissionStrings: string[] = [];
  mediaP.forEach((p) => {
    let spec = p.split("_");
    mediaPermissionStrings.push(spec[spec.length - 1].toLowerCase());
  });

  if (mediaPermissionStrings.length <= 0) return undefined;

  if (
    mediaPermissionStrings.includes("admin") ||
    ["viewer", "editor", "deleter"].every((x) =>
      mediaPermissionStrings.includes(x)
    )
  ) {
    description += "Full access";
  } else {
    description += mediaPermissionStrings.join(", ");
  }
  description += ")";

  return description;
}

export function getPermissionDescription(permissions: Permission[]) {
  let descriptions: string[] = [];
  permissionTypes.forEach((pt) => {
    const d = getSinglePermissionDescription(pt, permissions);
    if (d) descriptions.push(d);
  });
  return descriptions;
}

export function getDefaultDynEntryData(model: DynModel) {
  let entry = {};

  model.dynPropSets.forEach((p) => {
    entry = { ...entry, [p.tag]: "" };
  });

  return entry;
}

export function updateDynDataEntry(
  entry: DynDataEntry,
  tag: string,
  value: string | number | boolean
): DynDataEntry {
  let data = JSON.parse(entry.data);
  data[tag] = value;

  return { ...entry, data: JSON.stringify(data) };
}

export function extractNumber(value: string): string {
  const match = value.match(/^(-?[\d.]*\.?\d*)(\D+)$/);

  if (!match) {
    return value;
  }

  const numberPart = parseFloat(match[0]);
  return isNaN(numberPart) ? "0" : numberPart.toString();
}

export const Cursors: string[] = [
  "alias",
  "all-scroll",
  "auto",
  "cell",
  "context-menu",
  "col-resize",
  "copy",
  "crosshair",
  "default",
  "e-resize",
  "ew-resize",
  "grab",
  "grabbing",
  "help",
  "move",
  "n-resize",
  "ne-resize",
  "nesw-resize",
  "ns-resize",
  "nw-resize",
  "nwse-resize",
  "no-drop",
  "none",
  "not-allowed",
  "pointer",
  "progress",
  "row-resize",
  "s-resize",
  "se-resize",
  "sw-resize",
  "text",
  "vertical-text",
  "w-resize",
  "wait",
  "zoom-in",
  "zoom-out",
];

export function getStyleById(
  block: BlockData | undefined,
  styleProperty: string
): string {
  const element = block
    ? document.getElementById(`b${block?.id?.toString()}`)
    : undefined;
  const style = StyleTable.find((x) => x.name == styleProperty);
  const defaultValue = getDefaultValue(block?.blockType, style);

  if (style?.name === "margin-top") {
    console.log(styleProperty);
  }

  if (element) {
    let value = getStyleValue(element, style);

    if (
      style?.relatedValues &&
      style.relatedValues.find((x) => x.alias == value)
    ) {
      value =
        style.relatedValues.find((x) => x.alias == value)?.actual ?? value;
    }

    return !value || value === "" ? defaultValue : value?.toString();
  } else {
    return defaultValue;
  }
}

export function getDefaultValue(
  blockType: BlockType | undefined,
  styleEntry: Style | undefined
): string {
  if (!styleEntry) return "";

  if (blockType) {
    return (
      styleEntry.tagDependentDefaults?.find((x) => x.type === blockType)
        ?.default ?? styleEntry.defaultValue
    );
  }

  return styleEntry.defaultValue;
}

function getStyleValue(element: HTMLElement, style: any): string {
  if (style?.inherited) {
    let computedStyle = window.getComputedStyle(element);
    let value = computedStyle.getPropertyValue(style.name);

    if (style?.relatedAttributes) {
      style.relatedAttributes.forEach((attr: string) => {
        let value = computedStyle.getPropertyValue(attr);

        if (value && value !== "") return value;
      });
    }

    return value;
  } else {
    const value = element.style[style.name];
    if (style?.relatedAttributes) {
      style.relatedAttributes.forEach((attr: keyof CSSStyleDeclaration) => {
        let value = element.style[attr];

        if (value && value !== "") return value;
      });
    }

    return value;
  }
}

export function isStyleInheritedAndChanged(
  elementId: string,
  styleProperty: string,
  defaultValue: string
): boolean {
  const element = document.getElementById(elementId);
  const style = StyleTable.find((x) => x.name == styleProperty);

  if (!style || !style.inherited || !element) return false;

  const computedStyle = window.getComputedStyle(element);
  const value = computedStyle.getPropertyValue(styleProperty);
  return value?.toString() !== defaultValue;
}

export function getModelsDisplayName(models: Model[]): string {
  return `(${models.map(x => getModelDisplayName(x)).join(", ")})`
}
export function getModelDisplayName(model: Model): string {
  return model?.properties?.map((property) => {
    if (property.referencedValue === null)
      return "";

    switch (property.type) {
      case ModelPropertyType.model:
        return getModelDisplayName(property.referencedValue as Model);
      case ModelPropertyType.modelArray:
        const models = property.referencedValue as Model[];
        return getModelsDisplayName(models);
      default:
        return property.referencedValue.toString();
    }
  }).join(" | ") ?? '';
}

export function splitCssBackgroundImages(cssString: string): string[] {
  let results: string[] = [];
  let depth = 0;
  let quoteChar = null;
  let start = 0;

  for (let i = 0; i < cssString.length; i++) {
    const char = cssString[i];

    if (char === '"' || char === "'") {
      if (quoteChar === char) {
        quoteChar = null;
      } else if (!quoteChar) {
        quoteChar = char;
      }
    } else if (!quoteChar) {
      if (char === "(") {
        depth++;
      } else if (char === ")") {
        depth--;
      }
    }

    if (char === "," && depth === 0 && !quoteChar) {
      results.push(cssString.substring(start, i).trim());
      start = i + 1;
    }
  }

  if (start < cssString.length) {
    results.push(cssString.substring(start).trim());
  }

  return results;
}
