import { IconButton, Paper, useTheme, Button } from "@mui/material";
import React from "react";
import TitleBox from "../widgets/TitleBox";
import ViewQuiltOutlinedIcon from "@mui/icons-material/ViewQuiltOutlined";
import DeleteOutlinedIcon from "@mui/icons-material/DeleteOutlined";
import { DataGrid, GridActionsCellItem, GridColDef, GridRowSelectionModel } from "@mui/x-data-grid";
import { useNavigate } from "react-router-dom";
import AddIcon from "@mui/icons-material/Add";
import { useAppDispatch, useAppSelector } from "../../redux/hooks";
import useRouteParam from "../../hooks/useRouteParam";
import { Edit, Delete, ViewCompact } from "@mui/icons-material";
import DeleteConfirmationDialog from "../utils/DeleteConfirmationDialog";
import { createModel, createModelDefinition, defaultModel, defaultModelDefinition, deleteModelDefinition, getModelDefinitions, getModels, selectModelDefinitions, selectModels } from "../../redux/model/modelSlice";
import { deleteModel } from "../../redux/dyndata/dyndataSlice";
import ModelDefinitionDataGrid from "./ModelDefinitionDataGrid";

export default function ProjectModelDefinitions() {
    const projectId = useRouteParam("projectId");

    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>(
        []
    );

    const navigate = useNavigate();

    const theme = useTheme();
    const dispatch = useAppDispatch();
    const modelDefinitions = useAppSelector(selectModelDefinitions)

    React.useEffect(() => {
        dispatch(getModelDefinitions(projectId));
    }, []);

    const handleSelectedModelsDelete = React.useCallback(() => {
        selectedIds.forEach((id) => {
            dispatch(deleteModel(Number(id)));
        });
    }, [
        selectedIds,
        dispatch,
    ]);

    const handleModelCreate = React.useCallback(() => {
        dispatch(
            createModelDefinition({ ...defaultModelDefinition, projectId: projectId })
        )
            .unwrap()
            .then((payload) => {
                navigate(`/project-detail/${projectId}/definition/${payload.id}`);
            });
    }, [dispatch, defaultModel, projectId]);

    return (
        <>
            <TitleBox
                avatarIcon={<ViewQuiltOutlinedIcon />}
                mainTitle="Model definitions"
                subTitle={"Edit project related models."}
            >
                <Button
                    variant="contained"
                    sx={{ marginRight: theme.spacing(1) }}
                    onClick={handleModelCreate}
                    endIcon={<AddIcon />}
                >
                    ADD
                </Button>
                <DeleteConfirmationDialog onDeleteConfirm={handleSelectedModelsDelete}>
                    <IconButton
                        component="label"
                        disabled={selectedIds.length < 1}
                    >
                        <DeleteOutlinedIcon />
                    </IconButton>
                </DeleteConfirmationDialog>
            </TitleBox>
            <Paper sx={{ width: "100%", height: "700px", padding: theme.spacing(2) }}>
                <ModelDefinitionDataGrid modelDefinitions={modelDefinitions} selectedIds={selectedIds} setSelectedIds={setSelectedIds}/>
            </Paper>
        </>
    );
}
