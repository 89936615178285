import React from 'react';
import useRouteParam from '../../hooks/useRouteParam';
import { defaultModel, defaultModelDefinition, defaultModelDefinitionSection, defaultPropertyInfo, getModelDefinitions, getModels, selectModelById, selectModelDefinitionById, selectModelDefinitions, selectModels, updateModel, updateModelDefinition } from '../../redux/model/modelSlice';
import { Autocomplete, Button, FilledInput, FormControl, Grid, IconButton, InputLabel, MenuItem, Paper, Select, TextField, useTheme } from '@mui/material';
import { BackendError, useAppDispatch, useAppSelector } from '../../redux/hooks';
import TitleBox from '../widgets/TitleBox';
import { AccountTree, Add, Cancel, CheckBox, CheckBoxOutlineBlank, CheckBoxOutlined, Delete, DeleteOutlined, Edit, LanguageOutlined, Save } from '@mui/icons-material';
import { GridRowSelectionModel, GridRowModesModel, GridRowParams, MuiEvent, GridEventListener, GridRowId, GridRowModes, GridRowModel, GridColDef, GridActionsCellItem, DataGrid, GridRenderCellParams, GridRenderEditCellParams } from '@mui/x-data-grid';
import SnackBarOperations from '../../components/SnackBar/SnackBarOperations';
import { DynProps, DynDataType } from '../../redux/dyndata/types';
import yup from '../../validation/yup';
import ConfirmationPopover from '../utils/ConfirmationPopover';
import DeleteConfirmationDialog from '../utils/DeleteConfirmationDialog';
import StyledDataGrid from '../utils/StyledDataGrid';
import { ModelDefinition, ModelPropertyInfo, ModelPropertyType, ModelType } from '../../redux/model/types';
import { debounce } from 'lodash';
import ModelPropertyDataGrid from './ModelPropertyDataGrid';
import ModelDefinitionSectionsDataGrid from './ModelDefinitionSectionsDataGrid';

export default function ProjectModelDefinition() {

    const definitionId = useRouteParam('definitionId');

    const projectId = useRouteParam('projectId');

    const theme = useTheme();
    const [selectedIds, setSelectedIds] = React.useState<GridRowSelectionModel>([]);
    const [selectedSectionsIds, setSelectedSectionsIds] = React.useState<GridRowSelectionModel>([]);
    const [editModelDefinition, setEditModelDefinition] = React.useState(defaultModelDefinition);
    const projectModelDefinitions = useAppSelector(selectModelDefinitions);

    const currentModelDefinition = useAppSelector(selectModelDefinitionById(definitionId));
    const dispatch = useAppDispatch();

    React.useEffect(() => {
        if (currentModelDefinition)
            setEditModelDefinition({ ...currentModelDefinition });
    }, [currentModelDefinition])

    React.useEffect(() => {
        dispatch(getModelDefinitions(projectId));
    }, [])

    const handleSelectedPropertiesDelete = React.useCallback(() => {
        const changedModelDefinition = { ...editModelDefinition, properties: [...editModelDefinition.properties.filter(x => selectedIds.includes(x.id))] }
        dispatch(updateModelDefinition(changedModelDefinition));
    }, [editModelDefinition, selectedIds, dispatch]);

    const handleSelectedSectionsDelete = React.useCallback(() => {
        const changedModelDefinition = { ...editModelDefinition, modelDefinitionSections: [...editModelDefinition.modelDefinitionSections.filter(x => selectedIds.includes(x.id))] }
        dispatch(updateModelDefinition(changedModelDefinition));
    }, [editModelDefinition, selectedIds, dispatch]);

    const getModelValidationSchema = React.useCallback((id: number) => yup.object().shape({
        name: yup.string().required('Model definition name is required.').unique('Model with that name already exists in this project.', (name: string) => !projectModelDefinitions.filter(x => x.id != id).map(x => x.name).includes(name))
    }), [projectModelDefinitions]);

    const handleModelDefinitionSave = React.useCallback(async (changedModelDefinition: ModelDefinition) => {
        try {
            await getModelValidationSchema(changedModelDefinition.id).validate(changedModelDefinition);
            await dispatch(updateModelDefinition(changedModelDefinition));
        } catch (err) {
            if (err instanceof yup.ValidationError) {
                SnackBarOperations.error(err.message);
            } if (err instanceof BackendError) {
                SnackBarOperations.error(`Error saving model: ${err.message}`);
            }
            console.error(err);
        }
    }, [dispatch, getModelValidationSchema]);

    const debouncedChangeHandler = React.useCallback(
        debounce(handleModelDefinitionSave, 500),
        [handleModelDefinitionSave],
    );

    const handleModelDefinitionChange = React.useCallback((modelDefinition: ModelDefinition) => {
        setEditModelDefinition(modelDefinition);
        debouncedChangeHandler(modelDefinition);
    }, [debouncedChangeHandler, setEditModelDefinition]);

    const handlePropertyAdd = React.useCallback(() => {
        let changedModel = { ...editModelDefinition };
        changedModel.properties = [
            ...changedModel.properties,
            { 
                ...defaultPropertyInfo,
                modelDefinitionId: changedModel.id,
                order: changedModel.properties.length > 0 
                    ? Math.max(...changedModel.properties.map(x => x.order)) + 1 
                    : 1
            }
        ];
        dispatch(updateModelDefinition(changedModel));
    }, [editModelDefinition, dispatch]);

    const handleSectionAdd = React.useCallback(() => {
        let changedModel = { ...editModelDefinition };
        changedModel.modelDefinitionSections = [
            ...changedModel.modelDefinitionSections,
            { 
                ...defaultPropertyInfo,
                modelDefinitionId: changedModel.id,
                order: changedModel.modelDefinitionSections.length > 0 
                    ? Math.max(...changedModel.modelDefinitionSections.map(x => x.order)) + 1 
                    : 1 
            }
        ];
        dispatch(updateModelDefinition(changedModel));
    }, [editModelDefinition, dispatch]);

    return (
        <>
            <TitleBox
                avatarIcon={<LanguageOutlined />}
                mainTitle='Edit model definition'
                subTitle='Lorem ipsum'>
            </TitleBox>

            <Grid container spacing={3}>
                <Grid item xs={12}>
                    <Paper sx={{ width: '100%', padding: theme.spacing(2) }}>
                        <TextField sx={{ width: '250px', marginRight: theme.spacing(1) }} id="model-definition-name" label="Name" variant="filled" value={editModelDefinition.name} onChange={(e) => handleModelDefinitionChange({ ...editModelDefinition, name: e.target.value })} />
                        <FormControl variant="filled" sx={{ width: '250px' }}>
                            <InputLabel id="model-label">Model definition</InputLabel>
                            <Select
                                id='model-type-select'
                                labelId="model-type-label"
                                value={editModelDefinition.type}
                                input={<FilledInput />}
                                onChange={(e) => handleModelDefinitionChange({ ...editModelDefinition, type: e.target.value as ModelType })}
                            >
                                {Object.values(ModelType).map((type) => (
                                    <MenuItem key={type} value={type}>
                                        {type}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Paper>
                </Grid>
                <Grid item xs={8}>
                    <Paper sx={{ height: '700px', width: '100%', padding: theme.spacing(2), display: 'flex', flexDirection: 'column' }}>
                        <TitleBox
                            mainTitle='Properties'
                            subTitle='Lorem ipsum'
                            maintitleVariant='h6'
                            subTitleVariant='subtitle2'>
                            <DeleteConfirmationDialog onDeleteConfirm={handleSelectedPropertiesDelete}>
                                <IconButton component="label" disabled={selectedIds.length < 1}>
                                    <DeleteOutlined />
                                </IconButton>
                            </DeleteConfirmationDialog>
                            <Button variant="contained" onClick={handlePropertyAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                                ADD
                            </Button>
                        </TitleBox>
                        <ModelPropertyDataGrid
                            currentModelDefinition={currentModelDefinition}
                            editModelDefinition={editModelDefinition}
                            projectModelDefinitions={projectModelDefinitions}
                            selectedIds={selectedIds}
                            setSelectedIds={setSelectedIds} />
                    </Paper>
                </Grid>
                <Grid item xs={4}>
                    <Paper sx={{ height: '700px', width: '100%', padding: theme.spacing(2), display: 'flex', flexDirection: 'column' }}>
                        <TitleBox
                            mainTitle='Properties'
                            subTitle='Lorem ipsum'
                            maintitleVariant='h6'
                            subTitleVariant='subtitle2'>
                            <DeleteConfirmationDialog onDeleteConfirm={handleSelectedSectionsDelete}>
                                <IconButton component="label" disabled={selectedSectionsIds.length < 1}>
                                    <DeleteOutlined />
                                </IconButton>
                            </DeleteConfirmationDialog>
                            <Button variant="contained" onClick={handleSectionAdd} sx={{ marginLeft: theme.spacing(1) }} endIcon={<Add />}>
                                ADD
                            </Button>
                        </TitleBox>
                        <ModelDefinitionSectionsDataGrid
                            currentModelDefinition={currentModelDefinition}
                            editModelDefinition={editModelDefinition}
                            selectedIds={selectedSectionsIds}
                            setSelectedIds={setSelectedSectionsIds} />
                    </Paper>
                </Grid>
            </Grid>
        </>
    );
}